import React from 'react';
import propTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import FilterItem from './filterItem';


const Filter = (props) => {
    if (props.isVisible) {
        return (<div className="filter">
            <a href="#" className="toggle-filter" onClick={props.toggleFilter}>
                <span><i className="fa fa-arrow-right" /> Hide</span>
            </a>
            <div className="content">
                <div className="form-group">
                    <label>Query Name</label>
                    <input type="text" className="form-control" value={props.filterName} onChange={e => props.changeFormValue({ attribute: 'filterName', value: e.target.value })} />
                </div>
                {props.filterForm.map((obj, index) => (
                    <div>
                        <FilterItem
                            order={index}
                            productField={props.productField}
                            changeFormValue={props.changeFormValue}
                            filterForm={props.filterForm}
                        />
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                value={props.filterForm[index] ? props.filterForm[index].color : '#fff'}
                            />
                            <SketchPicker
                                color={props.filterForm[index] ? props.filterForm[index].color : '#fff'}
                                onChangeComplete={color => props.changeFormValue({ attribute: `filterForm.${index}.color`, value: color.hex })}
                            />
                        </div>
                    </div>
                ))}
                <div style={{ textAlign: 'center' }}>
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => props.changeFormValue({ attribute: `filterForm.${props.filterForm.length}`, value: {} })}
                    >
                        <i className="fa fa-plus" />
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-info"
                        disabled={props.isLoading}
                        onClick={() => {
                            props.applyFilter(props.filterForm, props.filterName);
                        }}
                    >
                        <i className="fa fa-check-square" /> Apply
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-success"
                        disabled={props.isLoading}
                        onClick={props.saveQuery}
                    >
                        <i className="fa fa-save" /> Save
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-default"
                        onClick={() => {
                            $('.main-header').fadeToggle(100);
                            $('.main-sidebar').fadeToggle(100);
                            $('#queryBuilder .filter').fadeToggle(100);
                        }}
                    >
                        <i className="fa fa-expand" />
                    </button>
                </div>
                { /*<p>{JSON.stringify(props.filterForm)}</p>*/ }
            </div>
        </div>);
    }
    return (
        <div className="filter not-visible">
            <a href="#" className="show" onClick={props.toggleFilter}><i className="fa fa-arrow-left" /> Show</a>
        </div>
    );
};

Filter.propTypes = {
    isVisible: propTypes.bool,
    toggleFilter: propTypes.func.isRequired,
    applyFilter: propTypes.func.isRequired,
    saveQuery: propTypes.func.isRequired,
    changeFormValue: propTypes.func.isRequired,
    filterForm: propTypes.array.isRequired,
    filterName: propTypes.string,
    isLoading: propTypes.bool,
};

Filter.defaultProps = {
    isVisible: true,
    productField: [],
    filterName: '',
    isLoading: false,
};

export default Filter;
