import React from 'react';
import { Link, browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { firestore } from '../../utils/firebase';
import { findProject } from '../layoutbuilder/actions';
import Checkbox from '../helpers/checkbox';

class UpdateProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            createdBy: '',
            modifiedBy: `${this.props.userInfo.firstname} ${this.props.userInfo.lastname}`,
            onLoading: false,
            productVisible: false,
            holdVisible: false,
            projectVisible: false,
            menuVisible: false,
            unitActionVisible: false,
            profileData: [],
            productFieldData: [],
            holdData: [{
                id: 'Create',
                name: 'Create',
                enable: false,
            }, {
                id: 'ViewOthersHold',
                name: 'View Hold Queue',
                enable: false,
            }, {
                id: 'ViewHoldQueueEmail',
                name: 'View Hold Queue Email',
                enable: false,
            },
            ],
            projectsData: [],
            menuData: [{
                id: 'PropertyManagement',
                name: 'Property Management',
                enable: false,
            }, {
                id: 'BuildingQuery',
                name: 'Building Query',
                enable: false,
            }, {
                id: 'UserManagement',
                name: 'User Management',
                enable: false,
            }, {
                id: 'Profiles',
                name: 'Profiles',
                enable: false,
            }],
            unitActionData: [{
                id: 'sendBrochure',
                name: 'Send Brochure',
                enable: false,
            }, {
                id: 'newProspect',
                name: 'New Prospect',
                enable: false,
            }, {
                id: 'hold',
                name: 'Hold',
                enable: false,
            }, {
                id: 'followUpAction',
                name: 'Follow Up Action',
                enable: false,
            }],
            role: 'admin',
            allProductFieldReadAccess: false,
            allProductFieldEditAccess: false,
            allProjectsEnable: false,
            allMenuEnable: false,
            allUnitActionEnable: false,
            viewHoldQueueEmail: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadProfile = this.loadProfile.bind(this);
        this.setActiveProject = this.setActiveProject.bind(this);
        this.setActiveHold = this.setActiveHold.bind(this);
        this.setActiveRead = this.setActiveRead.bind(this);
        this.setActiveEdit = this.setActiveEdit.bind(this);
        this.setActiveMenu = this.setActiveMenu.bind(this);
        this.setActiveUnitAction = this.setActiveUnitAction.bind(this);
        this.handleChangeEnable = this.handleChangeEnable.bind(this);
        this.changeAllProductFieldAccess = this.changeAllProductFieldAccess.bind(this);
        this.changeAllEnable = this.changeAllEnable.bind(this);
        this.dropdown = this.dropdown.bind(this);
    }

    componentWillMount() {
        const query = this.props.params;
        this.loadProfile(query.id);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.allProject !== this.state.projectsData) {
            this.setupProjects(nextProps.allProject);
        }
    }

    setupProductField() {
        const newProductField = [];
        const productFieldData = this.props.productField;
        const product = this.state.profileData.ProductField;

        if (product) {
            productFieldData.forEach((e) => {
                const a = {
                    name: e.name,
                    label: e.label,
                    type: e.type,
                    readAccess: product[e.name] ? product[e.name].readAccess : false,
                    editAccess: product[e.name] ? product[e.name].editAccess : false,
                };
                newProductField.push(a);
            });

            this.setState({ productFieldData: newProductField });
        } else {
            this.setState({ productFieldData });
        }
    }

    setupHold() {
        const newHold = [];
        const { holdData } = this.state;
        const hold = this.state.profileData.Hold;

        if (hold) {
            holdData.forEach((e) => {
                let enable = false;
                if (typeof hold[e.id] !== 'undefined') {
                    enable = hold[e.id].enable;
                }

                const a = {
                    id: e.id,
                    name: e.name,
                    enable,
                };
                newHold.push(a);
            });

            this.setState({ holdData: newHold });
        } else {
            this.setState({ holdData });
        }
    }

    setupProjects(projectsData) {
        const newProjects = [];
        const projects = this.state.profileData.Projects;

        if (Object.getOwnPropertyNames(projects).length !== 0) {
            projectsData.forEach((e) => {
                const a = {
                    id: e.Id,
                    name: e.Name,
                    enable: false,
                };

                if (projects[e.Id]) {
                    a.enable = projects[e.Id].enable;
                }

                newProjects.push(a);
            });
            this.setState({ projectsData: newProjects });
        } else {
            projectsData.forEach((e) => {
                const a = {
                    id: e.Id,
                    name: e.Name,
                    enable: false,
                };
                newProjects.push(a);
            });
            this.setState({ projectsData: newProjects });
        }
    }

    setupMenu() {
        const newMenu = [];
        const { menuData } = this.state;
        const menu = this.state.profileData.Menu;
        if (menu) {
            menuData.forEach((e) => {
                const a = {
                    id: e.id,
                    name: e.name,
                    enable: menu[e.id].enable,
                };
                newMenu.push(a);
            });

            this.setState({ menuData: newMenu });
        } else {
            this.setState({ menuData });
        }
    }

    setupUnitAction() {
        const newUnitAction = [];
        const { unitActionData } = this.state;
        const unitAction = this.state.profileData.UnitAction;
        if (unitAction) {
            unitActionData.forEach((e) => {
                const a = {
                    id: e.id,
                    name: e.name,
                    enable: unitAction[e.id].enable,
                };
                newUnitAction.push(a);
            });

            this.setState({ unitActionData: newUnitAction });
        } else {
            this.setState({ unitActionData });
        }
    }

    setActiveMenu(cell, row) {
        return (
            <Checkbox
                name={row.name}
                onChange={(() => {
                    this.setState({ menuData: this.handleChangeEnable(cell, row, this.state.menuData) });
                })}
                checked={cell}
            />
        );
    }

    setActiveProject(cell, row) {
        return (
            <Checkbox
                name={row.name}
                onChange={(() => {
                    this.setState({ projects: this.handleChangeEnable(cell, row, this.state.projectsData) });
                })}
                checked={cell}
            />
        );
    }

    setActiveHold(cell, row) {
        const disabled = row.id === 'ViewHoldQueueEmail' ? this.state.viewHoldQueueEmail : false;
        return (
            <Checkbox
                name={row.name}
                onChange={(() => {
                    this.setState({ holdData: this.handleChangeEnable(cell, row, this.state.holdData) });
                })}
                disabled={disabled}
                checked={cell}
            />
        );
    }

    setActiveRead(cell, row) {
        return (
            <Checkbox
                name={row.name}
                onChange={(() => {
                    this.handleChangeProduct(cell, row, 'read');
                })}
                checked={cell}
            />
        );
    }

    setActiveEdit(cell, row) {
        return (
            <Checkbox
                name={row.name}
                onChange={(() => {
                    this.handleChangeProduct(cell, row, 'edit');
                })}
                checked={cell}
            />
        );
    }

    setActiveUnitAction(cell, row) {
        return (
            <Checkbox
                name={row.name}
                onChange={(() => {
                    this.setState({ unitActionData: this.handleChangeEnable(cell, row, this.state.unitActionData) });
                })}
                checked={cell}
            />
        );
    }

    dropdown(value) {
        return value ? 'fa fa-caret-down' : 'fa fa-caret-right';
    }

    handleSubmit(event) {
        event.preventDefault();

        const objProductField = {};
        this.state.productFieldData.forEach((e) => {
            objProductField[e.name] = {
                name: e.name,
                label: e.label,
                type: e.type,
                readAccess: e.readAccess,
                editAccess: e.editAccess,
            };
        });

        const objHold = {};
        this.state.holdData.forEach((e) => {
            objHold[e.id] = {
                id: e.id,
                name: e.name,
                enable: e.enable,
            };
        });

        const objProjects = {};
        this.state.projectsData.forEach((e) => {
            objProjects[e.id] = {
                id: e.id,
                name: e.name,
                enable: e.enable,
            };
        });

        const objMenu = {};
        this.state.menuData.forEach((e) => {
            objMenu[e.id] = {
                id: e.id,
                name: e.name,
                enable: e.enable,
            };
        });

        const objUnitAction = {};
        this.state.unitActionData.forEach((e) => {
            objUnitAction[e.id] = {
                id: e.id,
                name: e.name,
                enable: e.enable,
            };
        });

        this.setState({ onLoading: true });

        const db = firestore.collection('Profiles').doc(this.state.profileData.firebaseId);

        return db.update({
            ProfileName: this.state.name,
            Description: this.state.description,
            Active: true,
            ModifiedDate: new Date(),
            ModifiedBy: this.state.modifiedBy,
            ProductField: objProductField,
            Hold: objHold,
            Projects: objProjects,
            Menu: objMenu,
            UnitAction: objUnitAction,
            Role: this.state.role,
        })
            .then(() => {
                this.setState({ onLoading: false });
                browserHistory.push('/profiles');
            })
            .catch((error) => {
                // The document probably doesn't exist.
                console.error('Error updating document: ', error);
            });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleChangeProduct(cell, row, type) {
        const productField = this.state.productFieldData;
        const foundIndex = productField.findIndex((e) => e.name === row.name);

        if (type === 'read') {
            if (row.readAccess === true) {
                row.editAccess = false;
            }
            row.readAccess = !row.readAccess;
        } else if (type === 'edit') {
            if (row.editAccess === false) {
                row.readAccess = true;
            }
            row.editAccess = !row.editAccess;
        }

        productField[foundIndex] = row;
        this.setState({ productFieldData: productField });
    }

    handleChangeEnable(cell, row, state) {
        const arrangedList = state;
        const foundIndex = state.findIndex((e) => e.name === row.name);

        if (row.id === 'ViewOthersHold') {
            if (row.enable) {
                this.setState({ viewHoldQueueEmail: true });
                arrangedList[2].enable = false;
            } else {
                this.setState({ viewHoldQueueEmail: false });
            }
        }

        row.enable = !row.enable;

        arrangedList[foundIndex] = row;
        return arrangedList;
    }

    changeAllProductFieldAccess(val, type) {
        const productField = this.state.productFieldData;

        if (type === 'read') {
            this.setState({ allProductFieldReadAccess: !val });
            productField.forEach((e) => {
                e.readAccess = !val;
                if (val === true) {
                    e.editAccess = false;
                    this.setState({ allProductFieldEditAccess: false });
                }
            });
        } else {
            this.setState({ allProductFieldEditAccess: !val });
            productField.forEach((e) => {
                e.editAccess = !val;
                if (val === false) {
                    e.readAccess = true;
                    this.setState({ allProductFieldReadAccess: true });
                }
            });
        }
    }

    changeAllEnable(val, state, data) {
        this.setState({ [state]: !val });
        data.forEach((e) => {
            e.enable = !val;
            if (e.id === 'ViewHoldQueueEmail') {
                if (e.enable) {
                    this.setState({ viewHoldQueueEmail: false });
                } else {
                    this.setState({ viewHoldQueueEmail: true });
                }
            }
        });
    }

    async loadProfile(id) {
        let profile = {};
        await firestore.collection('Profiles').doc(id).get()
            .then((doc) => {
                if (!doc.exists) {
                    console.log('No such document!');
                } else {
                    profile = doc.data();
                    profile.firebaseId = doc.id;
                    console.log('Document data:', doc.data());
                    this.setState({ profileData: profile });
                    this.setState({ name: profile.ProfileName });
                    this.setState({ description: profile.Description });
                    this.setState({ createdBy: profile.CreatedBy });
                    this.setState({ role: profile.Role });

                    this.setupProductField();
                    this.setupHold();
                    this.setupMenu();
                    this.setupUnitAction();
                }
            })
            .catch((err) => {
                console.log('Error getting documents', err);
            });

        await this.props.findProject();
    }

    render() {
        return (
            <div>
                <section className="content-header">
                    <h1>Update Profile</h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/">
                                <i className="fa fa-dashboard" />
                                {' '}
                                Home
                            </Link>
                        </li>
                        <li><Link to="/profiles">Profiles</Link></li>
                        <li className="active">Update</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box box-primary">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Profile Information</h3>
                                </div>
                                <form id="frmProfile" onSubmit={this.handleSubmit}>
                                    <div className="box-body">
                                        <div className="form-group required">
                                            <label htmlFor="name">Name:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                value={this.state.name}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="description">Description</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="description"
                                                value={this.state.description}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="createdBy">Created By</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="createdBy"
                                                value={this.state.createdBy}
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="modifiedBy">Modified By</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="modifiedBy"
                                                value={this.state.modifiedBy}
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="accessType">Access Type</label>
                                            <select name="role" ref="role" id="role" className="form-control" onChange={this.handleChange} value={this.state.role} required>
                                                <option key="admin" value="admin">Admin</option>
                                                <option key="management" value="management">Management</option>
                                                <option key="dm" value="dm">Mobile – DM</option>
                                                <option key="sales" value="sales">Mobile – Sales</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Profile Item</h3>
                                    </div>
                                    <div className="box-body">
                                        <button type="button" className="btn-link btn" onClick={() => this.setState({ productVisible: !this.state.productVisible })}>
                                            <i className={this.dropdown(this.state.productVisible)} />
                                            &nbsp;Product
                                        </button>
                                        {
                                            this.state.productVisible
                                                ? (
                                                    <BootstrapTable
                                                        bordered
                                                        condensed
                                                        striped
                                                        hover
                                                        data={this.state.productFieldData}
                                                    >
                                                        <TableHeaderColumn isKey dataField="label">Field Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="type">Field Type</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="readAccess" dataFormat={this.setActiveRead}>
                                                            <Checkbox
                                                                name="productFieldReadAll"
                                                                onChange={(() => {
                                                                    this.changeAllProductFieldAccess(this.state.allProductFieldReadAccess, 'read');
                                                                })}
                                                                checked={this.state.allProductFieldReadAccess}
                                                            />
                                                            &nbsp;
                                                            Read Access
                                                        </TableHeaderColumn>
                                                        <TableHeaderColumn dataField="editAccess" dataFormat={this.setActiveEdit}>
                                                            <Checkbox
                                                                name="productFieldEditAll"
                                                                onChange={(() => {
                                                                    this.changeAllProductFieldAccess(this.state.allProductFieldEditAccess, 'edit');
                                                                })}
                                                                checked={this.state.allProductFieldEditAccess}
                                                            />
                                                            &nbsp;
                                                            Edit Access
                                                        </TableHeaderColumn>
                                                    </BootstrapTable>
                                                )
                                                : null
                                        }
                                    </div>
                                    <div className="box-body">
                                        <button className="btn btn-link" type="button" onClick={() => this.setState({ holdVisible: !this.state.holdVisible })}>
                                            <i className={this.dropdown(this.state.holdVisible)} />
                                            &nbsp;Hold
                                        </button>
                                        {
                                            this.state.holdVisible
                                                ? (
                                                    <BootstrapTable
                                                        bordered
                                                        condensed
                                                        striped
                                                        hover
                                                        data={this.state.holdData}
                                                    >
                                                        <TableHeaderColumn isKey dataField="name">Field Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="enable" dataFormat={this.setActiveHold}>
                                                            <Checkbox
                                                                name="holdEnableAll"
                                                                onChange={(() => {
                                                                    this.changeAllEnable(this.state.allHoldEnable, 'allHoldEnable', this.state.holdData);
                                                                })}
                                                                checked={this.state.allHoldEnable}
                                                            />
                                                            &nbsp;
                                                            Enable
                                                        </TableHeaderColumn>
                                                    </BootstrapTable>
                                                )
                                                : null
                                        }
                                    </div>
                                    <div className="box-body">
                                        <button type="button" className="btn btn-link" onClick={() => this.setState({ projectVisible: !this.state.projectVisible })}>
                                            <i className={this.dropdown(this.state.projectVisible)} />
                                            &nbsp;Projects
                                        </button>
                                        {
                                            this.state.projectVisible
                                                ? (
                                                    <BootstrapTable
                                                        bordered
                                                        condensed
                                                        striped
                                                        hover
                                                        data={this.state.projectsData}
                                                    >
                                                        <TableHeaderColumn isKey dataField="name">Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="enable" dataFormat={this.setActiveProject}>
                                                            <Checkbox
                                                                name="projectsEnableAll"
                                                                onChange={(() => {
                                                                    this.changeAllEnable(this.state.allProjectsEnable, 'allProjectsEnable', this.state.projectsData);
                                                                })}
                                                                checked={this.state.allProjectsEnable}
                                                            />
                                                            &nbsp;
                                                            Enable
                                                        </TableHeaderColumn>
                                                    </BootstrapTable>
                                                )
                                                : null
                                        }
                                    </div>
                                    <div className="box-body">
                                        <button type="button" className="btn btn-link" onClick={() => this.setState({ menuVisible: !this.state.menuVisible })}>
                                            <i className={this.dropdown(this.state.menuVisible)} />
                                            &nbsp;Menu
                                        </button>
                                        {
                                            this.state.menuVisible
                                                ? (
                                                    <BootstrapTable
                                                        bordered
                                                        condensed
                                                        striped
                                                        hover
                                                        data={this.state.menuData}
                                                    >
                                                        <TableHeaderColumn isKey dataField="name">Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="enable" dataFormat={this.setActiveMenu}>
                                                            <Checkbox
                                                                name="menuEnableAll"
                                                                onChange={(() => {
                                                                    this.changeAllEnable(this.state.allMenuEnable, 'allMenuEnable', this.state.menuData);
                                                                })}
                                                                checked={this.state.allMenuEnable}
                                                            />
                                                            &nbsp;
                                                            Enable
                                                        </TableHeaderColumn>
                                                    </BootstrapTable>
                                                )
                                                : null
                                        }
                                    </div>
                                    <div className="box-body">
                                        <button type="button" className="btn btn-link" onClick={() => this.setState({ unitActionVisible: !this.state.unitActionVisible })}>
                                            <i className={this.dropdown(this.state.unitActionVisible)} />
                                            &nbsp;Unit Action
                                        </button>
                                        {
                                            this.state.unitActionVisible
                                                ? (
                                                    <BootstrapTable
                                                        bordered
                                                        condensed
                                                        striped
                                                        hover
                                                        data={this.state.unitActionData}
                                                    >
                                                        <TableHeaderColumn isKey dataField="name">Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="enable" dataFormat={this.setActiveUnitAction}>
                                                            <Checkbox
                                                                name="unitActionEnableAll"
                                                                onChange={(() => {
                                                                    this.changeAllEnable(this.state.allUnitActionEnable, 'allUnitActionEnable', this.state.unitActionData);
                                                                })}
                                                                checked={this.state.allUnitActionEnable}
                                                            />
                                                            &nbsp;
                                                            Enable
                                                        </TableHeaderColumn>
                                                    </BootstrapTable>
                                                )
                                                : null
                                        }
                                    </div>
                                    <div className="box-footer">
                                        <Link
                                            to="/profiles"
                                            className="btn btn-default"
                                            type="button"
                                        >
                                            Cancel
                                        </Link>
                                        <button type="submit" className="btn btn-info pull-right" disabled={this.state.onLoading}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.salesforce.userInfo,
        productField: state.salesforce.productField.fields,
        allProject: state.layoutBuilder.allProject,
    };
}

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        findProject,
    }, dispatch)
);

UpdateProfile.propTypes = {
    userInfo: PropTypes.object.isRequired,
    productField: PropTypes.object.isRequired,
    allProject: PropTypes.object.isRequired,
    findProject: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
};

UpdateProfile.defaultProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);
