import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
    fetchUser, logoutUser, getSalesforceToken, getAllProductField,
} from '../actions/firebase_actions';
import Header from './layout/Header';

class App extends Component {
    constructor(props) {
        super(props);
        this.lastActivityTime = null;
        this.props.fetchUser().then(() => {
            this.props.getSalesforceToken(this.props.getAllProductField);
        });
        this.logOut = this.logOut.bind(this);
        this.resetTimer = this.resetTimer.bind(this);
        this.checkMenu = this.checkMenu.bind(this);
    }

    componentWillMount() {
        window.onmousemove = this.resetTimer; // catches mouse movements
        window.onmousedown = this.resetTimer; // catches mouse movements
        window.onclick = this.resetTimer; // catches mouse clicks
        window.onscroll = this.resetTimer; // catches scrolling
        window.onkeypress = this.resetTimer;
    }

    componentWillUnmount() {
        window.onmousemove = null; // catches mouse movements
        window.onmousedown = null; // catches mouse movements
        window.onclick = null; // catches mouse clicks
        window.onscroll = null; // catches scrolling
        window.onkeypress = null;
    }

    logOut() {
        this.props.logoutUser().then(() => {
            this.props.fetchUser();
        });
    }

    resetTimer() {
        clearTimeout(this.lastActivityTime);
        this.lastActivityTime = setTimeout(() => {
            console.log('LOGOUT');
            this.props.logoutUser();
            window.location.href = '/';
        }, 30 * 1000 * 60);
    }

    checkMenu(value) {
        if (!this.props.userInfo.profile) return false;
        const menu = this.props.userInfo.profile.Menu;
        let returnData = false;

        if (typeof menu !== 'undefined') {
            returnData = menu[value].enable;
        }
        return returnData;
    }

    render() {
        const location = this.props.routing.locationBeforeTransitions === undefined ? {} : this.props.routing.locationBeforeTransitions;
        if (this.props.salesforce.token === null) {
            return (
                <div style={{ margin: '0 auto' }}>
                    <i className="fa fa-spinner fa-pulse fa-5x fa-fw" />
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }

        return (
            <div>
                <Header currentUser={this.props.currentUser} logoutUser={this.props.logoutUser} userInfo={this.props.userInfo} />
                <aside className="main-sidebar">
                    <section className="sidebar">
                        <div className="user-panel">
                            <div className="pull-left image">
                                <img src={this.props.currentUser ? this.props.currentUser.photoURL : `/${require('../img/user2-160x160.jpg')}`} className="img-circle" alt="avatar" />
                            </div>
                            <div className="pull-left info">
                                <p>{this.props.currentUser ? this.props.currentUser.displayName : '...'}</p>
                                <Link to="/profile">
                                    <i className="fa fa-circle text-success" />
                                    {' '}
                                    Sales
                                </Link>
                            </div>
                        </div>
                        <ul className="sidebar-menu" data-widget="tree">
                            {this.checkMenu('PropertyManagement')
                                ? (
                                    <li className={location.pathname === '/' ? 'active' : ''} style={{ display: this.props.userInfo.role === 'admin' ? 'block' : 'none' }}>
                                        <Link to="/building">
                                            <i className="fa fa-building-o" />
                                            {' '}
                                            <span>Property Management </span>
                                        </Link>
                                    </li>
                                )
                                : null}
                            {this.checkMenu('BuildingQuery')
                                ? (
                                    <li>
                                        <Link to="/query">
                                            <i className="fa fa-bar-chart" />
                                            {' '}
                                            <span>Building Query </span>
                                        </Link>
                                    </li>
                                )
                                : null}
                            {this.checkMenu('UserManagement')
                                ? (
                                    <li className="treeview" style={{ display: this.props.userInfo.role === 'admin' ? 'block' : 'none' }}>
                                        <a href="#">
                                            <i className="fa fa-user" />
                                            {' '}
                                            <span>User Management </span>
                                            <span className="pull-right-container">
                                                <i className="fa fa-angle-left pull-right" />
                                            </span>
                                        </a>
                                        <ul className="treeview-menu">
                                            <li>
                                                <Link to="/user">
                                                    <i className="fa fa-user" />
                                                    {' '}
                                                    User
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                )
                                : null}
                            {this.checkMenu('Profiles')
                                ? (
                                    <li>
                                        <Link to="/profiles">
                                            <i className="fa fa-users" />
                                            {' '}
                                            <span>Profiles </span>
                                        </Link>
                                    </li>
                                )
                                : null}
                        </ul>
                    </section>
                </aside>
                <div className="content-wrapper">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchUser, logoutUser, getSalesforceToken, getAllProductField,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser, routing: state.routing, salesforce: state.salesforce, userInfo: state.salesforce.userInfo,
    };
}

App.propTypes = {
    fetchUser: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    currentUser: PropTypes.any,
    children: PropTypes.element.isRequired,
    routing: PropTypes.object,
    salesforce: PropTypes.object.isRequired,
    userInfo: PropTypes.object,
    getSalesforceToken: PropTypes.func.isRequired,
    getAllProductField: PropTypes.func.isRequired,
};

App.defaultProps = {
    currentUser: {},
    routing: {},
    userInfo: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
