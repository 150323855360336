import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import 'react-bootstrap-table/css/toastr.css';
import { browserHistory, Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    createNewLayout, loadAllLayout, deleteBuilding, getLayoutRef, changePublishStatusFirebase,
} from './actions';

class LayoutList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            filteredBuilding: [],
        };
        this.newLayout = this.newLayout.bind(this);
        this.filterLayout = this.filterLayout.bind(this);
    }

    componentWillMount() {
        this.props.loadAllLayout();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.layoutBuilder.allLayout !== this.props.layoutBuilder.allLayout) {
            this.filterLayout(nextProps.layoutBuilder.allLayout);
        }
        return true;
    }

    getTotalRoom(cell) {
        let totalRoom = 0;
        if (cell === undefined || cell === null) return 0;
        for (let i = 0; i < cell.length; i++) {
            if (cell[i]) {
                for (let j = 0; j < cell[i].length; j++) {
                    if (cell[i][j]) {
                        if (cell[i][j].product) totalRoom++;
                    }
                }
            }
        }
        this;
        return totalRoom;
    }

    filterLayout(allLayout) {
        const profileProjects = this.props.profile.Projects;
        const filteredBuilding = [];

        allLayout.forEach((e) => {
            if (!e.project) return;
            if (profileProjects[e.project.Id] && profileProjects[e.project.Id].enable) {
                filteredBuilding.push(e);
            }
        });

        this.setState({ filteredBuilding });
    }

    newLayout() {
        browserHistory.push('/building/new');
        this.props.createNewLayout();
    }

    rowGetter(i) {
        return this.state.rows[i];
    }

    render() {
        const filteredBuilding = this.state.filteredBuilding.sort((a, b) => {
            const aDate = typeof a.lastModifiedDate === 'object' ? a.lastModifiedDate.toDate() : new Date(a.lastModifiedDate);
            const bDate = typeof b.lastModifiedDate === 'object' ? b.lastModifiedDate.toDate() : new Date(b.lastModifiedDate);
            if (aDate <= bDate) return 1;
            return -1;
        });
        return this.props.children ? this.props.children : (
            <div className="col-lg-12 col-sm-12">
                <h2>Building List</h2>
                <hr />
                { this.props.profile.Role === 'admin'
                    ? (
                        <div className="col-lg-4 col-lg-offset-4 margin10">
                            <button type="button" className="btn btn-primary btn-block" onClick={this.newLayout}>
                                <i className="fa fa-plus" />
                                {' '}
                                New Building Layout
                            </button>
                        </div>
                    )
                    : null}
                <div className="clearfix" />
                <p style={{ textAlign: 'center' }}>
                    {this.props.common.isLoading ? <i className="fa fa-3x fa-spinner fa-spin" /> : null}
                </p>
                <div className="">
                    <BootstrapTable
                        bordered
                        condensed
                        striped
                        hover
                        search
                        data={filteredBuilding}
                        pagination
                        options={{
                            sizePerPage: 15,
                            onRowClick: (data) => data,
                        }}
                        selectRow={{
                            clickToSelect: true,
                            className: (row, isSelect) => (isSelect ? 'selected' : ''),
                        }}
                    >
                        <TableHeaderColumn dataField="key" hidden isKey>Key</TableHeaderColumn>
                        <TableHeaderColumn dataSort dataField="layoutProjectName">Project Name</TableHeaderColumn>
                        <TableHeaderColumn dataSort dataField="layoutBuildingName">Building Name</TableHeaderColumn>
                        <TableHeaderColumn
                            dataSort
                            dataField="layoutBuilderData"
                            dataFormat={(cell) => <span>{new Intl.NumberFormat().format(this.getTotalRoom(cell))}</span>}
                        >
                            Total Unit
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataSort
                            dataField="published"
                            dataFormat={(cell) => <span>{cell || 'Unpublished'}</span>}
                        >
                            Published Status
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataSort
                            dataField="lastModifiedDate"
                            dataFormat={(cell, row) => `${row.lastModifiedBy ? row.lastModifiedBy.displayName : ''}
                                at ${moment(typeof cell === 'object' ? cell.toDate() : cell).format('DD MMM YYYY HH:mm')} `}
                        >
                            Last Modified
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="firebaseKey"
                            width="80"
                            dataFormat={(cell, row) => (
                                <div className="btn-group-vertical">
                                    <Link to={`/building/edit/${cell}`} type="button" className="btn btn-default"><i className="fa fa-eye" /></Link>
                                    {row.published === 'Unpublished' || row.published === undefined
                                        ? <button aria-label="delete" type="button" onClick={() => this.props.deleteBuilding(cell, row)} className="btn btn-danger"><i className="fa fa-trash" /></button> : ''}
                                    <button aria-label="change publish status" type="button" onClick={() => this.props.changePublishStatusFirebase(cell, row.published)} className="btn btn-primary"><i className="fa fa-send" /></button>
                                </div>
                            )}
                        >
                            #
                        </TableHeaderColumn>

                    </BootstrapTable>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        layoutBuilder: state.layoutBuilder,
        common: state.common,
        profile: state.salesforce.userInfo.profile,
    };
}

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        createNewLayout,
        loadAllLayout,
        deleteBuilding,
        changePublishStatusFirebase,
    }, dispatch)
);

LayoutList.propTypes = {
    layoutBuilder: PropTypes.object.isRequired,
    loadAllLayout: PropTypes.func.isRequired,
    deleteBuilding: PropTypes.func.isRequired,
    changePublishStatusFirebase: PropTypes.func.isRequired,
    children: PropTypes.object,
    common: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
};

LayoutList.defaultProps = {
    children: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutList);
