module.exports = {
    // Change this to your firebase configuration! (Add Firebase to your web app)
    FIREBASE_CONFIG: {
        apiKey: 'AIzaSyC6no-K6Xm2UqQ3RQ0mSI0PWTwDcR6v-jY',
        authDomain: 'raimon-land-sales-matrix-prod.firebaseapp.com',
        databaseURL: 'https://raimon-land-sales-matrix-prod.firebaseio.com',
        projectId: 'raimon-land-sales-matrix-prod',
        storageBucket: 'raimon-land-sales-matrix-prod.appspot.com',
        messagingSenderId: '274522762803',
        cloudFunctionUrl: window.location.hostname === 'localhost'
            ? 'http://localhost:5001/raimon-land-sales-matrix-prod/us-central1/'
            : 'https://us-central1-raimon-land-sales-matrix-prod.cloudfunctions.net/',
    },
};
