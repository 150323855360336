import React, { Component } from 'react';
import PropTypes from 'prop-types';

/*
const colorCoding = {
    Available: '#fff',
    Booked: '#D3D3D3',
    Sold: '#A9A9A9',
    Reserved: '#FFFF33',
    'Not Available': '#0033FF',
};*/

const TransferStatus = {
    'Awaiting Hand Over': 'AW',
    Tentative: 'TN',
    'Inspected 1': 'I1',
    'Inspected 2': 'I2',
    'Inspected 3': 'I3',
    'Inspected 3+': 'I+',
    'Paid 100%': 'PD',
    Recognized: 'RZ',
    'moved-in wo transfer': 'MT',
    'furniture-in wo transfer': 'FT',
    Transferred: 'TF',
    'Final Notice': 'FN',
    Problem: 'PB',
};

const ResaleStatus = {
    'Not Available': 'NA',
    Available: 'AV',
    Reserved: 'RS',
    Booked: 'BK',
    Sold: 'SD',
};

const LeaseStatus = {
    'Not Available': 'NA',
    Available: 'AV',
    Reserved: 'RS',
    Booked: 'BK',
    Leased: 'LD',
};

class Cell extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            deleted: false,
            ctrlKey: false,
        };
    }

    render() {
        let fontColor = '#000';
        let tda = '';
        let tdp = '';
        let resale = '';
        let sales = '';
        let roomtype = '';
        let ppsqm = 0;
        let ts = '-';
        let ls = '-';
        let rs = '-';
        const statusElement = [];
        if (this.props.product) {
            if (this.props.profile.ProductField.Actual_Area__c.readAccess) {
                tda = this.props.product.Actual_Area__c;
            } else {
                tda = '';
            }

            if (this.props.profile.ProductField.Price__c.readAccess) {
                tdp = `${this.props.product.Price__c.toLocaleString()}` || '0';
            } else {
                tdp = '';
            }

            if (this.props.profile.ProductField.Price_Per_Square__c.readAccess) {
                ppsqm = `${this.props.product.Price_Per_Square__c ? this.props.product.Price_Per_Square__c.toLocaleString() : 0}` || 0;
            } else {
                ppsqm = '';
            }

            sales = this.props.product.Sales_Status__c;
            resale = (this.props.product.Resale_Status__c) ? this.props.product.Resale_Status__c.substring(0, 1) : '';

            if (this.props.profile.ProductField.Unit_Type_Name__c.readAccess) {
                roomtype = this.props.product.Unit_Type_Name__c ? `(${this.props.product.Unit_Type_Name__c})` : '';
            } else {
                roomtype = '';
            }
            
            ts = this.props.profile.ProductField.Transfer_Status__c.readAccess ? TransferStatus[this.props.product.Transfer_Status__c] : '';
            ls = this.props.profile.ProductField.Lease_Status__c.readAccess ? LeaseStatus[this.props.product.Lease_Status__c] : '';
            rs = this.props.profile.ProductField.Resale_Status__c.readAccess ? ResaleStatus[this.props.product.Resale_Status__c] : '';
            const color = {
                Foreign: 'red',
                Thai: 'blue',
                '': '#000',
            };
            fontColor = color[this.props.product.Quota__c];
            // console.log(this.props.product);
            if (this.props.product.Sales_Status__c === 'Sold' ||
                this.props.product.Sales_Status__c === 'Booked') {
                if (this.props.profile.ProductField.Sold_Area__c.readAccess) {
                    tda = this.props.product.Sold_Area__c;
                } else {
                    tda = '';
                }

                if (this.props.profile.ProductField.Net_Unit_Furniture_Price__c.readAccess) {
                    tdp = this.props.product.Net_Unit_Furniture_Price__c ? this.props.product.Net_Unit_Furniture_Price__c.toLocaleString() : 0;
                } else {
                    tdp = '';
                }

                if (this.props.profile.ProductField.Final_Unit_Price__c.readAccess){
                    tdp = tdp === 0 ? (this.props.product.Final_Unit_Price__c ? this.props.product.Final_Unit_Price__c.toLocaleString() : 0) : tdp;
                } else {
                    tdp = '';
                }

                if (this.props.profile.ProductField.Final_Price_Sq__c.readAccess) {
                    ppsqm = this.props.product.Final_Price_Sq__c ? this.props.product.Final_Price_Sq__c.toLocaleString() : 0;
                } else {
                    ppsqm = '';
                }
            }
        }
        if (this.props.height > 40) {
            statusElement.push(<span key={Math.random()} style={{ position: 'absolute', left: 1, top: '35%' }}>{ts}</span>);
            statusElement.push(<span key={Math.random()} style={{ position: 'absolute', left: '50%', top: '35%' }}>{rs}</span>);
            statusElement.push(<span key={Math.random()} style={{ position: 'absolute', right: 1, top: '35%' }}>{ls}</span>);
        }
        return (
            <div
                style={{
                    border: '1px solid #222',
                    height: this.props.height,
                    width: this.props.width,
                    bottom: (this.props.bottom),
                    left: (this.props.left),
                    position: 'absolute',
                    fontSize: 10,
                    cursor: 'pointer',
                }}
                tabIndex={-1}
                data-salesstatus={sales}
                data-resalestatus={resale}
                onClick={this.props.onClick}
            >
                {this.props.bgColor.map((obj, index) => (
                    <div
                        key={Math.random()}
                        style={{
                            height: this.props.height - 2,
                            width: (this.props.width / this.props.bgColor.length) - 4,
                            position: 'absolute',
                            backgroundColor: obj,
                            left: ((this.props.width / this.props.bgColor.length) * index) + 2,
                        }}
                    />
                ))}
                <span style={{ width: '100%', height: '100%', color: fontColor }}>
                    <span style={{ position: 'absolute', left: 1 }}>{this.props.label}{roomtype}</span>
                    <span style={{ position: 'absolute', left: 1, bottom: 0 }}>{tda}</span>
                    {statusElement}
                    <span style={{ position: 'absolute', right: 1 }}>{tdp}</span>
                    <span style={{ position: 'absolute', right: 1, bottom: 0 }}>{ppsqm}</span>
                </span>
            </div>);
    }
}

Cell.propTypes = {
    label: PropTypes.string,
    bgColor: PropTypes.array,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    product: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
};

Cell.defaultProps = {
    label: '',
    selected: false,
    product: null,
    align: 0,
    bgColor: ['#fff'],
};

export default Cell;
