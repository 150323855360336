import React from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const FindProduct = (props) => (
    <div className="modal modal-default" id="findProductModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 className="modal-title" id="myModalLabel">Find Unit</h4>
                </div>
            </div>
            <div className="modal-body">

                <BootstrapTable
                    data={props.products || []}
                    pagination
                    options={{
                        sizePerPage: 5,
                        onRowClick: (data) => {
                            $('#findProductModal').modal('hide');
                            props.pickProduct(data);
                        },
                    }}
                    selectRow={{
                        clickToSelect: true,
                        className: (row, isSelect) => (isSelect ? 'selected' : ''),
                    }}
                >
                    <TableHeaderColumn dataField="Id" isKey>Product ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="Name" filter={{ type: 'TextFilter', placeholder: 'Name..' }}>Name</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="Price__c"
                        dataFormat={(cell) => <span>{new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(cell)}</span>}
                    >
                        Total Deed Price
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="Sales_Status__c">Sales Status</TableHeaderColumn>
                    <TableHeaderColumn dataField="Unit_Status__c">Unit Status</TableHeaderColumn>
                </BootstrapTable>
            </div>
        </div>
    </div>
);

FindProduct.propTypes = {
    products: PropTypes.any,
    pickProduct: PropTypes.func.isRequired,
};

FindProduct.defaultProps = {
    products: [],
};

export default FindProduct;
