import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap-social';

import { Router, browserHistory } from 'react-router';
import { persistStore } from 'redux-persist';
import { syncHistoryWithStore } from 'react-router-redux';
import Routes from './routes';
import configureStore from './configureStore';
import './css/AdminLTE.css';
import './css/skins/_all-skins.css';
import './js/app.min';
import './js/adminlte.min';
// for bundling your styles
import './bundle.scss';
import './js/jquery.slimscroll.min';

const store = configureStore({}, () => {
    ready = true;
});
const history = syncHistoryWithStore(browserHistory, store);

persistStore(store, {
    whitelist: ['salesforce', 'currentUser'],
}, () => {
    ReactDOM.render((
        <Provider store={store}>
            <Router history={history} routes={Routes} />
        </Provider>
    ), document.getElementById('root'));
});
