import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import 'react-bootstrap-table/css/toastr.css';
import { Link } from 'react-router';
import { firestore } from '../../utils/firebase';
import moment from '../../../../node_modules/moment/moment';

class ProfileList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileList: [],
        };
        this.loadProfiles = this.loadProfiles.bind(this);
        this.setActive = this.setActive.bind(this);
        this.actionButton = this.actionButton.bind(this);
    }

    componentWillMount() {
        this.loadProfiles();
    }

    setActive(cell) {
        if (cell) {
            return '<i aria-hidden="true" class="fa fa-check" />';
        }
        return '<i aria-hidden="true" class="fa fa-close" />';
    }

    actionButton(cell) {
        const editButton = (
            <Link className="btn btn-warning btn-xs text-center" type="button" to={`/profiles/update/${cell}`}>
                <i className="fa fa-pencil" />
                {' '}
                Update
            </Link>
        );
        return <ButtonGroup>{editButton}</ButtonGroup>;
    }

    async loadProfiles() {
        const profileList = [];
        await firestore.collection('Profiles').get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    const b = doc.data();
                    b.firebaseId = doc.id;
                    profileList.push(b);
                });
                this.setState({ profileList });
            })
            .catch((err) => {
                console.log('Error getting documents', err);
            });
    }

    render() {
        return (
            <div>
                <section className="content-header">
                    <h1>Profiles</h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/">
                                <i className="fa fa-dashboard" />
                                {' '}
                                Home
                            </Link>
                        </li>
                        <li className="active">Profiles</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-4 col-md-offset-4 text-center" style={{ marginBottom: 30 }}>
                            <Link className="btn btn-success text-center" type="button" to="/profiles/new">
                                <i className="fa fa-user-plus" />
                                {' '}
                                New Profile
                            </Link>
                        </div>
                        <div className="clearfix" />
                        <div className="col-lg-12 col-xs-12">
                            <BootstrapTable
                                bordered
                                condensed
                                striped
                                hover
                                search
                                data={this.state.profileList}
                            >
                                <TableHeaderColumn
                                    isKey
                                    dataField="firebaseId"
                                    width="85"
                                    dataFormat={this.actionButton}
                                />
                                <TableHeaderColumn dataField="ProfileName">Profile Name</TableHeaderColumn>
                                <TableHeaderColumn width={30} dataField="Active" dataFormat={this.setActive}>Active</TableHeaderColumn>
                                <TableHeaderColumn dataField="Description">Description</TableHeaderColumn>
                                <TableHeaderColumn dataField="CreatedDate" dataFormat={(cell) => moment(cell).format('DD-MM-YYYY hh:mm a')}>Created Date</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="ModifiedDate"
                                    dataFormat={(cell) => moment(typeof cell === 'object' ? cell.toDate() : cell).format('DD-MM-YYYY hh:mm a')}
                                >
                                    Last Modified Date
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="UserCountAssigned">User Count Assigned</TableHeaderColumn>
                                <TableHeaderColumn dataField="CreatedBy">Created By</TableHeaderColumn>
                                <TableHeaderColumn dataField="ModifiedBy">Last Modified By</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ProfileList;
