const DEFAULT_STATE = {
    firebaseKey: '',
    allProfile: [],
    userData: {},
};

const fnList = {
    ALL_PROFILE_FROM_FIREBASE: (state, payload) => ({
        ...state,
        allProfile: payload,
    }),
};

export default (state = DEFAULT_STATE, { type, payload }) => {
    const fn = fnList[type];
    if (typeof fn === 'function') {
        return fn(state, payload);
    }
    return state;
};
