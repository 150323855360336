import swal from 'sweetalert';
import _ from 'lodash';
import { sfRequest, getBase64FromImageUrl } from '../../utils/common';
import {
    firebaseDb, firebaseStorage, firestore, firebase,
} from '../../utils/firebase';

/*
    Rows: how many rows
    Cols: How many cols
    width/height: width and height of the images
 */
export const buildNewCell = (rows, cols, { width, height }) => {
    const builder = [];
    for (let x = 0; x < rows; x++) {
        builder[x] = [];
        for (let y = 0; y < cols; y++) {
            builder[x][y] = {
                rowNo: x,
                colNo: y,
                deleted: false,
                selected: false,
                width: width / cols,
                height: height / rows,
                left: (y * (width / cols)),
                bottom: (x * (height / rows)),
                label: `${(x + 1)}-${y + 1}`,
            };
        }
    }
    return {
        type: 'CREATE_NEW_LAYOUT',
        payload: builder,
    };
};

export const changeImage = (image) => ({
    type: 'CHANGE_LAYOUT_BUILDER_IMAGE',
    payload: image,
});

export const toggleCell = ({ row, col, ctrlClicked }) => ({
    type: 'TOGGLE_CELL',
    payload: {
        row,
        col,
        ctrlClicked,
    },
});

export const deleteCell = () => ({
    type: 'DELETE_CELL',
});

export const clearCell = () => ({
    type: 'CLEAR_CELL',
    payload: null,
});

export const changeAttribute = (attributeName, value) => ({
    type: 'CHANGE_ATTRIBUTE',
    payload: {
        attributeName,
        value,
    },
});

export const toggleSelectAll = (status) => ({
    type: 'TOGGLE_SELECT_ALL',
    payload: status,
});

export const undo = () => ({
    type: 'UNDO',
    payload: null,
});

export const changePublishStatus = (publishStatus) => ({
    type: 'CHANGE_PUBLISH_STATUS',
    payload: publishStatus,
});

export const changePublishStatusFirebase = (key, layoutPublished) => (
    (dispatch) => {
        swal({
            title: 'Publish this building?',
            text: 'Please state where this building will be published. The input should be "Unpublished", "Web", "Mobile" or "Both"',
            type: 'input',
            showCancelButton: true,
            closeOnConfirm: false,
            animation: 'slide-from-top',
            inputPlaceholder: 'Unpublished, Mobile, Web or Both',
        }, (inputValue) => {
            if (inputValue) {
                let newInputValue = inputValue.toLowerCase();
                if (layoutPublished.toLowerCase() === newInputValue) {
                    swal.showInputError(`No change made, current publish status is ${layoutPublished}. Please choose other publish status.`);
                    return false;
                }
                if (newInputValue !== 'web' && newInputValue !== 'mobile' && newInputValue !== 'both' && newInputValue !== 'unpublished') {
                    swal.showInputError('You need to type either "Unpublished", "Web", "Mobile" or "Both"');
                    return false;
                }
                newInputValue = newInputValue.charAt(0).toUpperCase() + newInputValue.toLowerCase().slice(1);
                firestore.collection('layout').doc(key).update({
                    layoutPublished: newInputValue,
                    published: newInputValue,
                }).then(() => {
                    dispatch({
                        payload: {
                            key,
                            value: newInputValue,
                        },
                        type: 'FIREBASE_CHANGE_PUBLISH_STATUS',
                    });
                    swal({
                        title: 'Publish status changed!',
                        text: `Publish status of ${key} building has been changed.`,
                        type: 'success',
                    }, () => {
                        window.location.reload();
                    });
                })
                    .catch((err) => swal(err.message));
                return true;
            }
            swal.showInputError('You need to type either "Unpublished", "Web", "Mobile" or "Both"');
            return false;
        });
    }
);

export const findProject = (q = '') => (
    (dispatch, getState) => {
        const salesforceToken = getState().salesforce.token;
        const query = `Select Id, Name, District__c, ProjectID__c, Province__c, Active__c from Project__c Where Active__c = true and Name LIKE '%${q}%' Order By Name LIMIT ${20}`;
        sfRequest(null, {
            method: 'GET',
            url: salesforceToken.instanceUrl,
            query,
            accessToken: salesforceToken.accessToken,
        }, dispatch, 'ALL_PROJECT');
    }
);

export const findProductByProject = (q = '') => (
    (dispatch, getState) => {
        const salesforceToken = getState().salesforce.token;
        const query = `Select Id, Name, District__c, ProjectID__c, Province__c, Active__c from Project__c Where Name LIKE '%${q}%' Order By Name LIMIT ${20}`;
        sfRequest(null, {
            method: 'GET',
            url: salesforceToken.instanceUrl,
            query,
            accessToken: salesforceToken.accessToken,
        }, dispatch, 'ALL_PROJECT');
    }
);

export const findAllProduct = (projectId) => (
    (dispatch, getState) => {
        const productFields = getState().salesforce.productField.fields;
        const salesforceToken = getState().salesforce.token;
        let query = '';
        productFields.map((value) => (query += ` ${value.name}, `));
        query = `Select ${query.substring(0, query.length - 2)} from Product2 where  Project_Id__c = '${projectId}' LIMIT 2000`;
        sfRequest(null, {
            method: 'GET',
            url: salesforceToken.instanceUrl,
            query,
            accessToken: salesforceToken.accessToken,
        }, dispatch, 'PRODUCT_BY_PROJECT');
    }
);

export const pickProject = (project) => ({
    type: 'PICK_PROJECT',
    payload: project,
});

export const pickProduct = (product) => (
    (dispatch, getState) => {
        const { layoutBuilderData } = getState().layoutBuilder;
        const productPicked = [];
        for (let i = 0; i < layoutBuilderData.length; i++) {
            if (layoutBuilderData[i]) {
                for (let j = 0; j < layoutBuilderData[i].length; j++) {
                    if (layoutBuilderData[i][j]) {
                        if (layoutBuilderData[i][j].product) productPicked.push(layoutBuilderData[i][j].product.Id);
                    }
                }
            }
        }
        if (productPicked.indexOf(product.Id) > -1) {
            return swal({
                title: 'Product already picked',
                text: `This product ${product.Name} has been linked to another cell.`,
                type: 'warning',
            });
        }
        dispatch({
            type: 'PICK_PRODUCT',
            payload: product,
        });
    }
);

export const unlinkProduct = () => ({
    type: 'UNLINK_PRODUCT',
});

export const changeBuildingName = (name) => ({
    type: 'CHANGE_BUILDING_NAME',
    payload: name,
});

export const changeImageRatio = (ratio) => ({
    type: 'CHANGE_LAYOUT_IMAGE_RATIO',
    payload: ratio,
});

export const selectManyCell = ({ row, col }) => ({
    type: 'SELECT_MANY_CELL',
    payload: { row, col },
});

export const saveBuilding = () => (
    async (dispatch, getState) => {
        const currentUser = {
            displayName: getState().currentUser.displayName,
            email: getState().currentUser.email,
            uid: getState().currentUser.uid,
        };
        const { layoutBuilder } = getState();
        let errorMessage = '';
        if (layoutBuilder.project.Id === undefined) errorMessage += 'Please pick project from list.\n';
        if (layoutBuilder.layoutBuildingName === '') errorMessage += 'Please input building name.';

        if (errorMessage !== '') {
            swal({
                title: 'Failed to save building',
                text: errorMessage,
                type: 'warning',
            });
            return;
        }
        dispatch({
            type: 'PENDING',
        });

        // Get a key for a new Post if it's not an edit/update.
        const newPostKey = layoutBuilder.firebaseKey === '' ? firebaseDb.ref('layout').push().key : layoutBuilder.firebaseKey;
        const imageExtension = layoutBuilder.layoutImage.split(';')[0].split('/')[1];
        try {
            await firebaseStorage.ref(`/layout/${newPostKey}.${imageExtension}`).putString(layoutBuilder.layoutImage, 'data_url');
            const batch = firestore.batch();
            const node = {
                // layoutBuilderData: layoutBuilder.layoutBuilderData,
                layoutImage: `/layout/${newPostKey}.${imageExtension}`,
                layoutImageRatio: layoutBuilder.layoutImageRatio,
                project: layoutBuilder.project,
                layoutProject: layoutBuilder.project,
                layoutBuildingName: layoutBuilder.layoutBuildingName,
                lastModifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
                lastModifiedBy: currentUser,
                published: layoutBuilder.published,
                layoutPublished: layoutBuilder.published,
                // layoutProductPicked: layoutBuilder.layoutProductPicked,
            };
            if (layoutBuilder.firebaseKey === '') {
                node.createdDate = firebase.firestore.FieldValue.serverTimestamp();
                node.createdBy = currentUser;
            }
            // firebaseDb.ref(`/layout/${newPostKey}/`).set(node);
            batch.set(firestore.collection('layout').doc(newPostKey), node);
            Object.keys(layoutBuilder.layoutProductPicked)
                .map((key) => batch.set(firestore.collection(`/layout/${newPostKey}/layoutProductPicked`).doc(key),
                    layoutBuilder.layoutProductPicked[key]));
            Object.keys(layoutBuilder.layoutBuilderData).forEach((key) => {
                batch.set(
                    firestore.collection(`/layout/${newPostKey}/layoutBuilderData`).doc(key),
                    {
                        ...layoutBuilder.layoutBuilderData[key],
                    },
                );
            });
            await batch.commit();
        } catch (err) {
            swal({
                title: 'An error occured',
                text: `Background Image is required.\n ${err.message}`,
                type: 'error',
            });
        }
        dispatch({
            type: 'SAVED',
            payload: newPostKey,
        });
    }
);

export const createNewLayout = () => ({
    type: 'NEW_LAYOUT',
});
const layoutRef = null;

export const getLayoutRef = () => layoutRef;

export const loadAllLayout = () => (
    async (dispatch) => {
        // layoutRef = firebaseDb.ref('/layout/').orderByChild('lastModifiedDate').limitToFirst(100);
        const layouts = await firestore.collection('layout').limit(100).get();
        dispatch({ type: 'LOADING' });
        const newDataStructure = [];
        layouts.forEach((layout) => {
            const layoutData = layout.data();
            newDataStructure.push({
                ...layout.data(),
                firebaseKey: layout.id,
                layoutProjectName: layoutData.project ? layoutData.project.Name : '',
            });
        });
        dispatch({
            type: 'ALL_LAYOUT_FROM_FIREBASE',
            payload: newDataStructure,
        });
        dispatch({ type: 'DONE' });
    }
);

const layoutListener = null;

export const loadOneLayout = (key) => (
    async (dispatch, getState) => {
        dispatch({
            type: 'TOGGLE_LOADING',
        });
        const { allLayout } = getState().layoutBuilder;
        let selectedIndex = 0;
        if (allLayout.length > 0) {
            for (let x = 0; x < allLayout.length; x++) {
                if (key === allLayout[x].firebaseKey) selectedIndex = x;
            }
            const products = getState().layoutBuilder.productList[allLayout[selectedIndex].project.Id];
            if (products === undefined) {
                const query = `Select Id, Name, Price__c, Transfer_Status__c, Quota__c, Room_Type__c, Price_Per_Square__c,
                    Unit_Type__c, Tower__c, Floor__c, Lease_Status__c,
                    Unit_Status__c, Sales_Status__c, Detail_Room__c, Resale_Status__c, Actual_Area__c
                    from Product2 where Project_Id__c = '${allLayout[selectedIndex].project.Id}'`;
                const salesforceToken = getState().salesforce.token;
                sfRequest(null, {
                    method: 'GET',
                    url: salesforceToken.instanceUrl,
                    query,
                    accessToken: salesforceToken.accessToken,
                }, dispatch, 'PRODUCT_BY_PROJECT');
            }
        }
        let layout = await firestore.collection('layout').doc(key).get();
        layout = {
            ...layout.data(),
            id: layout.id,
        };

        const [productPicked, builderData, url, allProduct] = await Promise.all([
            firestore.collection(`layout/${key}/layoutProductPicked`).get(),
            firestore.collection(`layout/${key}/layoutBuilderData`).get(),
            firebaseStorage.ref(layout.layoutImage).getDownloadURL(),
            firestore.collection(`project/${layout.project.Id}/products`).get(),
        ]);

        layout.layoutProductPicked = {};
        productPicked.forEach((product) => {
            layout.layoutProductPicked[product.id] = product.data();
        });
        layout.layoutBuilderData = [];
        builderData.forEach((builder) => {
            layout.layoutBuilderData[builder.id] = builder.data();
        });

        const base64Image = await getBase64FromImageUrl(url);
        const products = [];
        allProduct.forEach((product) => products.push(product.data()));
        dispatch({
            type: 'LOAD_ONE_LAYOUT',
            payload: {
                firebaseKey: key,
                layoutImage: base64Image,
                layoutBuilder: {
                    ...layout,
                },
                allProduct: products,
            },
        });
        dispatch({
            type: 'TOGGLE_LOADING',
        });
    }
);

export const unloadLayout = () => {
    if (layoutListener) layoutListener.off('value');
};

export const toggleAlign = () => ({
    type: 'TOGGLE_ALIGN',
});

export const deleteBuilding = (key, row) => (
    (dispatch) => {
        swal({
            title: 'Are you sure?',
            text: `You will not be able to recover this ${key} building file!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, delete it!',
            closeOnConfirm: false,
        }, () => {
            firestore.collection('archiveLayout').doc(key).set(row);
            firestore.collection('layout').doc(key).delete().then((result) => {
                dispatch({
                    payload: result,
                    type: 'DELETE_BUILDING',
                });
                swal('Deleted!', 'Your building has been deleted.', 'success', () => {
                    window.location.reload();
                });
            })
                .catch((err) => swal(err.message));
        });
    }
);

export const syncProductData = () => (
    async (dispatch, getState) => {
        const productFields = getState().salesforce.productField.fields;
        const salesforceToken = getState().salesforce.token;
        const { firebaseKey } = getState().layoutBuilder;
        const builderData = getState().layoutBuilder.layoutBuilderData;
        const productNeeded = [];
        for (let i = 0; i < builderData.length; i++) {
            if (builderData[i]) {
                Object.keys(builderData[i]).forEach((j) => {
                    if (builderData[i][j]) {
                        if (builderData[i][j].product) productNeeded.push(builderData[i][j].product.Id);
                    }
                });
            }
        }
        if (firebaseKey === undefined) return;
        let query = 'SELECT Count(Id) TotalCount';
        query += ` FROM Product2 WHERE Project_Id__c = '${getState().layoutBuilder.layoutProject.Id}'`;
        const counted = await fetch(
            `${salesforceToken.instanceUrl}/services/data/v40.0/query/?q=${encodeURIComponent(query)}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${salesforceToken.accessToken}`,
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                },
                timeout: 5000,
            },
        ).then((rsp) => rsp.json());
        const productRecord = {};
        await Promise.all(_.times(Math.ceil(counted.records[0].TotalCount / 1000), async (i) => {
            query = 'SELECT ';
            productFields.map((value) => {
                query += ` ${value.name}, `;
                return null;
            });
            query = query.substring(0, query.length - 2);
            query += `, Active_Q_Member__c FROM Product2 WHERE
                Project_Id__c = '${getState().layoutBuilder.layoutProject.Id}' ORDER BY LastModifiedDate DESC LIMIT 1000 OFFSET ${i * 1000}`;
            const sfResult = await fetch(
                `${salesforceToken.instanceUrl}/services/data/v40.0/query/?q=${encodeURIComponent(query)}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${salesforceToken.accessToken}`,
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                    },
                    timeout: 5000,
                },
            ).then((rsp) => rsp.json());
            for (let j = 0; j < sfResult.records.length; j++) {
                if (productNeeded.indexOf(sfResult.records[j].Id) > -1) {
                    productRecord[sfResult.records[j].Id] = sfResult.records[j];
                }
            }
        }));
        firebaseDb.ref(`/layout/${firebaseKey}/layoutProductPicked/`)
            .set(productRecord)
            .then((res) => console.log('SUCCESS', res))
            .catch((err) => console.log(err));
    }
);

export const addCell = (imageHeight) => ({
    type: 'ADD_CELL',
    payload: imageHeight,
});

export default '';
