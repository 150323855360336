import { isAdmin } from './auth';
import FireBaseTools from './firebase';

export function requireAuth(nextState, replace) {
    FireBaseTools.fetchUser().then((user) => {
        if (user === null) {
            window.location = '/login';
            replace({
                pathname: '/login',
                state: {
                    nextPathname: nextState.location.pathname,
                },
            });
        }
    });
}

export function requireAdmin(nextState, replace, callback) {
    isAdmin().then((loggedIn) => {
        if (!loggedIn) {
            replace({
                pathname: '/forbidden',
                state: {
                    nextPathname: nextState.location.pathname,
                },
            });
        }
        callback();
    });
}

export default '';
