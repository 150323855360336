import React from 'react';
import propTypes from 'prop-types';
import { findSelectedCell } from '../../utils/common';


const ProductInfoModal = (props) => {
    const profileProductField = props.profile.ProductField;
    const selectedCell = findSelectedCell(props.layoutBuilderData);
    let content = '';
    if (selectedCell.length > 0) {
        if (selectedCell[0].product !== undefined && selectedCell[0].product !== null) {
            const productId = selectedCell[0].product.Id;
            if (props.productPicked[productId] !== undefined) {
                content = (
                    <table className="table table-striped">
                        <tbody>
                            { profileProductField.Name.readAccess ?
                                <tr>
                                    <td>Unit Name</td>
                                    <td>:</td>
                                    <td><a target="_blank" href={`${props.instanceUrl}/${selectedCell[0].product.Id}`}>{props.productPicked[productId].Name}</a></td>
                                </tr>
                            : null }
                            { profileProductField.Price__c.readAccess ?
                                <tr>
                                    <td>Title Deed Price</td>
                                    <td>:</td>
                                    <td>{new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(props.productPicked[productId].Price__c)}</td>
                                </tr>
                            : null }
                            { profileProductField.Sales_Status__c.readAccess ?
                                <tr>
                                    <td>Sales Status</td>
                                    <td>:</td>
                                    <td>{props.productPicked[productId].Sales_Status__c}</td>
                                </tr>
                                : null }
                            { profileProductField.Unit_Status__c.readAccess ?
                                <tr>
                                    <td>Unit Status</td>
                                    <td>:</td>
                                    <td>{props.productPicked[productId].Unit_Status__c}</td>
                                </tr>
                            : null }
                        </tbody>
                    </table>);
            }
        }
    }
    return (
        <div
            className="modalAttribute productInfo"
            id="modalInfo"
            style={{ left: 80, width: 400, height: 220,  display: content === '' ? 'none' : 'block'}}
            ref={props.modalRef}
        >
            <div className="close">
                <button className="close-btn btn" onClick={() => props.toggleSelectAll(false)}>
                    <i className="fa fa-times" />
                </button>
            </div>
            <div className="header" style={{ backgroundColor: '#aaa' }}>Product Information</div>
            <div className="content">{content}</div>
        </div>);


};

ProductInfoModal.propTypes = {
    modalRef: propTypes.func.isRequired,
    layoutBuilderData: propTypes.array.isRequired,
    toggleSelectAll: propTypes.func.isRequired,
    instanceUrl: propTypes.string,
    productPicked: propTypes.object.isRequired,
    profile: propTypes.object.isRequired,
};

ProductInfoModal.defaultProps = {
    instanceUrl: 'https://salesforce.com/',
};

export default ProductInfoModal;
