import React from 'react';
import PropTypes from 'prop-types';


const FindProject = (props) => {
    let textInput;
    return (<div className="modal fade" id="findProjectModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 className="modal-title" id="myModalLabel">Find Project</h4>
                </div>
                <div className="modal-body">
                    <div className="table-responsive">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search for..." ref={q => { textInput = q } } />
                            <span className="input-group-btn">
                                <button
                                    className="btn btn-default"
                                    type="button"
                                    onClick={() => props.findProject(textInput.value)}
                                >
                                    <i className="fa fa-search" />
                                </button>
                            </span>
                        </div>
                        <table className="table table-stripped" id="projectTable">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Short Name</th>
                                    <th>Province</th>
                                    <th>District</th>
                                </tr>
                            </thead>
                            <tbody>
                               {props.allProject.map(obj => (
                                   <tr key={obj.Id}>
                                       <td><a data-dismiss="modal" aria-label="Close" href="#" className="" onClick={() => props.pickProject(obj)}>{obj.Id}</a></td>
                                       <td>{obj.Name}</td>
                                       <td>{obj.Short_Name__c}</td>
                                       <td>{obj.Province__c}</td>
                                       <td>{obj.District__c}</td>
                                   </tr>
                               ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};

FindProject.propTypes = {
    allProject: PropTypes.any.isRequired,
    findProject: PropTypes.func.isRequired,
    pickProject: PropTypes.func.isRequired,
};

export default FindProject;