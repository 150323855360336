import { firestore } from '../../utils/firebase';

export const loadAllProfile = () => (
    async () => {
        const allProfile = [];
        await firestore.collection('Profiles').get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    allProfile.push(doc.data());
                });
                // console.log(allProfile);
                return allProfile;
            })
            .catch((err) => {
                console.log('Error getting documents', err);
            });
    }
);

export default '';
