import React, { Component } from 'react';

class Error404 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
        };
    }
    render() {
        return (
            <div>
                
                <section className="content">
                    <div className="error-page">
                        <h2 className="headline text-red">404</h2>
                        <div className="error-content">
                            <h3><i className="fa fa-warning text-red" /> Page Not Found.</h3>
                            <p>The page you are looking for cannot be found</p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

}

export default Error404;
