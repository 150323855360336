import React from 'react';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import domtoimage from 'dom-to-image';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import Filter from './filter';
import {
    loadOneLayout, loadSfProduct, applyFilter, saveQuery,
} from './actions';
import Cell from './cell';
import ProductInfoModal from './productInfoModal';

class NewQuery extends React.Component {
    constructor() {
        super();
        this.state = {
            file: '',
            imagePreviewUrl: '',
            imageWidth: 0,
            imageHeight: 0,
            imageRatio: 100,
            rows: 0,
            cols: 0,
            project: {},
            filterShown: true,
            filterForm: [{}],
            filterName: '',
            productInfoShown: false,
            pickedProduct: {},
            top: 200,
            left: 100,
            printing: false,
            filteredProduct: [],
            filterProductDone: false,
        };
        this.attributeModal = {};
        this.changeState = this.changeState.bind(this);
        this.saveQuery = this.saveQuery.bind(this);
        this.findLabel = this.findLabel.bind(this);
        this.print = this.print.bind(this);
    }

    componentWillMount() {
        const query = this.props.params;
        this.props.loadOneLayout(query.id, query.queryKey, () => {
            this.props.applyFilter(this.state.filterForm, this.state.filterName);
        });
        document.body.style = 'overflow: scroll';
    }

    componentWillReceiveProps(nextProps) {
        this.filterProduct();
        const { layoutImage } = nextProps.buildingQuery;
        if (layoutImage !== null) {
            const image = new Image();
            image.onload = () => {
                this.setState({
                    imageWidth: image.width,
                    imageHeight: image.height,
                });
            };
            image.src = layoutImage;
            this.setState({
                filterForm: nextProps.buildingQuery.filterForm,
                filterName: nextProps.buildingQuery.filterName,
            });
        }
        $(this.attributeModal).draggable({
            handle: '.header',
            containment: '#queryBuilder',
            scroll: false,
        });
        return true;
    }

    filterProduct() {
        const product = this.props.profile.ProductField;
        const all = this.props.productField;

        const newAll = [];
        all.forEach((e) => {
            if (e.name === product[e.name].name) {
                if (product[e.name].readAccess === true) {
                    newAll.push(e);
                }
            }
        });

        this.setState({ filteredProduct: newAll });
        if (this.state.filterProductDone === false) {
            this.setState({ filterProductDone: true });
            this.setState({ filteredProduct: newAll });
        }
    }

    changeState({ attribute, value }) {
        const { state } = this;
        _.set(state, attribute, value);
        this.setState(state);
    }

    findLabel(fieldName) {
        let labelName = fieldName;
        this.props.productField.forEach((val) => {
            if (val.name === fieldName) labelName = val.label;
        });
        return labelName;
    }

    saveQuery() {
        const query = this.props.params;
        this.props.saveQuery(this.state.filterForm, this.state.filterName, query.id);
    }

    print() {
        if (this.state.printing) return;
        this.setState({ printing: true });
        /*
        domtoimage.toJpeg(document.getElementById('queryContent'),
            { bgcolor: '#fff', quality: 0.5 })
        .then((dataUrl) => {
            const link = document.createElement('a');
            link.download = `${this.props.buildingQuery.layoutProject.Name}_${this.props.buildingQuery.layoutBuildingName}.jpg`;
            link.href = dataUrl;
            // link.target = '_blank';
            link.click();
            this.setState({ printing: false });
        }); */

        domtoimage.toBlob(document.getElementById('queryContent'), { bgColor: '#fff' })
            .then((blob) => {
                FileSaver.saveAs(blob, `${this.props.buildingQuery.layoutProject.Name}_${this.props.buildingQuery.layoutBuildingName}.png`);
                this.setState({ printing: false });
            });
    }

    render() {
        const imagePreviewUrl = this.props.buildingQuery.layoutImage;
        const { queryResult } = this.props.buildingQuery;
        let $imagePreview = null;
        let loading = <div />;
        if (this.props.isLoading) {
            loading = (
                <div style={{ margin: '0 auto' }}>
                    <i className="fa fa-spinner fa-pulse fa-5x fa-fw" />
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }
        if (imagePreviewUrl && this.state.imageHeight > 0) {
            $imagePreview = (
                <div
                    className="builder-background"
                    style={{
                        height: this.state.imageHeight * (this.props.buildingQuery.layoutImageRatio / 100),
                        width: this.state.imageWidth * (this.props.buildingQuery.layoutImageRatio / 100),
                        background: `url(${imagePreviewUrl})  no-repeat center center`,
                        position: 'relative',
                        margin: '10px auto',
                    }}
                >
                    {_.times(this.props.buildingQuery.layoutBuilderData.length, (floorNo) => {
                        const floors = [];
                        if (!this.props.buildingQuery.layoutBuilderData[floorNo]) return floors;
                        const currentFloor = this.props.buildingQuery.layoutBuilderData[floorNo];
                        Object.keys(currentFloor).forEach((i) => {
                            const unitData = this.props.buildingQuery.layoutBuilderData[floorNo][i];
                            if (unitData !== null && unitData !== undefined) {
                                const bgColor = [];
                                let exists;
                                let { product } = unitData;
                                if (product) {
                                    product = this.props.buildingQuery.layoutProductPicked[unitData.product.Id];
                                }
                                if (unitData.product) {
                                    Object.keys(queryResult).forEach((index) => {
                                        if (this.state.filterForm[index] && queryResult[index].records) {
                                            exists = queryResult[index].records.find((el) => (el.Id === unitData.product.Id));
                                            if (exists !== undefined) bgColor.push(this.state.filterForm[index].color);
                                        }
                                    });
                                }
                                floors.push(
                                    <Cell
                                        bgColor={bgColor}
                                        rowNo={unitData.rowNo}
                                        colNo={unitData.colNo}
                                        height={parseFloat(unitData.height)}
                                        width={parseFloat(unitData.width)}
                                        left={parseFloat(unitData.left)}
                                        bottom={parseFloat(unitData.bottom)}
                                        product={product}
                                        align={unitData.align}
                                        label={unitData.label}
                                        imageHeight={this.state.imageHeight}
                                        profile={this.props.profile}
                                        onClick={() => {
                                            const imageHeight = this.state.imageHeight * (this.props.buildingQuery.layoutImageRatio / 100);
                                            let top = (imageHeight - parseFloat(unitData.bottom));
                                            top = parseFloat(unitData.bottom) < 300 ? top - 400 : top;
                                            this.setState({
                                                productInfoShown: true,
                                                pickedProduct: product,
                                                top,
                                                left: parseFloat(unitData.left),
                                            });
                                        }}
                                    />,
                                );
                            }
                        });
                        return floors;
                    })}
                </div>
            );
        }
        return (
            <div id="queryBuilder">
                {loading}
                <Filter
                    isVisible={this.state.filterShown}
                    productField={this.state.filteredProduct}
                    toggleFilter={() => this.changeState({ attribute: 'filterShown', value: !this.state.filterShown })}
                    filterForm={this.state.filterForm}
                    changeFormValue={this.changeState}
                    applyFilter={this.props.applyFilter}
                    saveQuery={this.saveQuery}
                    filterName={this.state.filterName}
                    isLoading={this.props.isLoading}
                />
                <section className="content-header">
                    <h1>{this.state.filterName}</h1>
                    <h4>
                        {this.props.buildingQuery.layoutProject.Name}
                        {' '}
                        -
                        {' '}
                        {this.props.buildingQuery.layoutBuildingName}
                    </h4>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/">
                                <i className="fa fa-dashboard" />
                                {' '}
                                Home
                            </Link>
                        </li>
                        <li><Link to="/query"> Building Query</Link></li>
                        <li className="active"> New</li>
                    </ol>
                    <div className="">
                        <button className="btn btn-default" onClick={this.print}>
                            <i className="fa fa-print" />
                            {' '}
                            {this.state.printing ? 'Printing....' : 'Print'}
                        </button>
                    </div>
                </section>
                <div style={{
                    position: 'fixed', right: 10, top: 50, zIndex: 10000,
                }}
                >
                    <button
                        className="btn btn-default btn-sm"
                        onClick={() => {
                            $('.main-header').fadeToggle(200);
                            $('.main-sidebar').fadeToggle(200);
                            $('#queryBuilder .filter').fadeToggle(200);
                        }}
                    >
                        <i className="fa fa-expand" />
                    </button>
                </div>
                <section className="content" id="queryContent">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            {this.state.filterForm.map((obj, index) => (
                                <div className="legendItem col-lg-2 col-md-4 col-sm-4 col-xs-6">
                                    <div className="color" style={{ backgroundColor: obj.color }} />
                                    <p className="text">
                                        {index + 1}
                                        .
                                        {
                                            Object.keys(obj).map((item, index1) => {
                                                const text = index1 === 0 ? '' : ' AND ';
                                                return obj[item].field ? `${text} ${this.findLabel(obj[item].field)} ${obj[item].operator}
                                                ${typeof obj[item].value === 'object' ? moment(obj[item].value).format('DD-MMMM-YYYY') : obj[item].value}` : '';
                                            })
                                        }

                                    </p>
                                </div>
                            ))}
                            <div className="clearfix" />
                        </div>
                        <div id="query" className="col-md-12" style={{ backgroundColor: 'rgba(0, 0, 0, 0.07)' }}>
                            <div className="layout-builder" style={{ position: 'relative' }}>
                                {$imagePreview}
                                <ProductInfoModal
                                    product={this.state.pickedProduct}
                                    changeFormValue={this.changeState}
                                    reference={(ref) => (this.attributeModal = ref)}
                                    isVisible={this.state.productInfoShown && this.state.pickedProduct !== undefined}
                                    top={this.state.top}
                                    left={this.state.left}
                                    instanceUrl={this.props.salesforce.instanceUrl}
                                    profile={this.props.profile}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

NewQuery.propTypes = {
    loadOneLayout: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    buildingQuery: PropTypes.object.isRequired,
    productField: PropTypes.array.isRequired,
    applyFilter: PropTypes.func.isRequired,
    saveQuery: PropTypes.func.isRequired,
    salesforce: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
};

NewQuery.defaultProps = {
    isLoading: false,
};

function mapStateToProps(state) {
    return {
        buildingQuery: state.buildingQuery,
        isLoading: state.buildingQuery.loading,
        productField: state.salesforce.productField.fields,
        salesforce: state.salesforce.token,
        profile: state.salesforce.userInfo.profile,
    };
}

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        loadOneLayout,
        loadSfProduct,
        applyFilter,
        saveQuery,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(NewQuery);
