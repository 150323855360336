import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';
import { fetchUser, updateUser } from '../../actions/firebase_actions';
import Loading from '../helpers/loading';
import ChangePassword from './change_password';

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.props.fetchUser();
        this.state = {
            message: '',
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onFormSubmit(event) {
        event.preventDefault();
        const email = this.refs.email.value;
        const displayName = this.refs.displayName.value;
        this.props.updateUser({ email, displayName }).then((data) => {
            if (data.payload.errorCode) {
                this.setState({ message: data.payload.errorMessage });
            } else {
                this.setState({
                    message: 'Updated successfuly!',
                });
            }
        });
    }

    render() {
        if (!this.props.currentUser) {
            return <Loading />;
        }

        return (
            <div>
                <section className="content-header">
                    <h1>User Settings Page</h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/">
                                <i className="fa fa-dashboard" />
                                {' '}
                                Home
                            </Link>
                        </li>
                        <li className="active">Settings</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="col-md-12 col-lg-10 col-sm-12">
                        <form id="frmProfile" onSubmit={this.onFormSubmit}>
                            <p>{this.state.message}</p>
                            <br />
                            <div className="form-group">
                                <label htmlFor="email">Email: </label>
                                <input
                                    type="text"
                                    defaultValue={this.props.currentUser.email}
                                    className="form-control"
                                    id="email"
                                    ref="email"
                                    placeholder="Email"
                                    name="email"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="displayName">Display name: </label>
                                <input
                                    type="text"
                                    defaultValue={this.props.currentUser.displayName}
                                    className="form-control"
                                    ref="displayName"
                                    id="displayName"
                                    placeholder="Display name"
                                    name="displayName"
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">Update</button>
                        </form>
                        <hr />
                        <ChangePassword />
                        <hr />
                        <div className="install-app">
                            <a className="btn btn-default" href="itms-services://?action=download-manifest&url=https://download.interaktiv.sg/manifest.plist">
                                <i className="fa fa-download fa-5x" />
                                {' '}
                                <br />
                                Install Mobile App
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchUser, updateUser }, dispatch);
}

function mapStateToProps(state) {
    return { currentUser: state.currentUser };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
