import React, { Component } from 'react';
import { browserHistory, Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
    loginUser, fetchUser, loginWithProvider, getUserInfo, storeLoginTimestamp,
} from '../../actions/firebase_actions';

const w = window;
const d = document;
const e = d.documentElement;
const g = d.getElementsByTagName('body')[0];
const x = w.innerWidth || e.clientWidth || g.clientWidth;
const y = w.innerHeight || e.clientHeight || g.clientHeight;

class UserLogin extends Component {
    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.loginWithProvider = this.loginWithProvider.bind(this);
        this.state = {
            message: '',
            errorState: 'hidden',
            loading: false,
        };
        document.body.className = 'hold-transition login-page';
    }

    componentWillUnmount() {
        document.body.className = 'hold-transition skin-green sidebar-mini';
    }

    async onFormSubmit(event) {
        event.preventDefault();
        const email = this.refs.email.value;
        const password = this.refs.password.value;
        this.setState({
            loading: true,
        });
        const data = await this.props.loginUser({ email, password });
        if (data.payload.errorCode) {
            this.setState({ message: data.payload.errorMessage, errorState: '', loading: false });
        } else {
            this.props.getUserInfo(data.payload.uid).then(() => {
                this.props.storeLoginTimestamp();
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000);
            });
        }
    }

    loginWithProvider(provider) {
        this.props.loginWithProvider(provider).then((data) => {
            if (data.payload.errorCode) {
                this.setState({ message: data.payload.errorMessage });
            } else {
                browserHistory.push('/profile');
            }
        });
    }

    render() {
        return (
            <div id="needBg">
                <div
                    className="background-box"
                    style={{
                        width: x,
                        height: y,
                    }}
                />
                <div className="login-box">
                    <img src={require('../../img/logo.png')} className="logo" alt="Logo Raimon Land" />
                    <form id="frmLogin" role="form" onSubmit={this.onFormSubmit}>
                        {this.state.loading ? <i className="fa text-center fa-spinner fa-spin fa-3x fa-fw" /> : ''}
                        <p>
                            <div className={`alert alert-warning ${this.state.errorState}`} role="alert">
                                {this.state.message}
                            </div>
                        </p>
                        <h2>Login</h2>
                        <div className="form-group">
                            <label htmlFor="txtEmail">Email address</label>
                            <input
                                type="email"
                                className="form-control"
                                id="txtEmail"
                                ref="email"
                                placeholder="Enter email"
                                name="email"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="txtPass">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="txtPass"
                                ref="password"
                                placeholder="Password"
                                name="password"
                            />
                        </div>
                        <button disabled={this.state.loading} type="submit" className="btn btn-default btn-block">Login</button>
                        <br />
                        <h5><Link to="/reset">Forgot password?</Link></h5>

                    </form>
                </div>
            </div>
        );
    }
}

UserLogin.propTypes = {
    loginUser: PropTypes.func.isRequired,
    loginWithProvider: PropTypes.func.isRequired,
    storeLoginTimestamp: PropTypes.func.isRequired,
    getUserInfo: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    loginUser,
    fetchUser,
    loginWithProvider,
    getUserInfo,
    storeLoginTimestamp,
}, dispatch);

function mapStateToProps(state) {
    return { currentUser: state.currentUser };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);
