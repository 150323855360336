

// / FIREBASE AUTH ACTIONS
export const LOGIN_WITH_PROVIDER_FIREBASE = 'LOGIN_WITH_PROVIDER_FIREBASE';
export const REGISTER_FIREBASE_USER = 'REGISTER_FIREBASE_USER';
export const LOGIN_FIREBASE_USER = 'LOGIN_FIREBASE_USER';
export const FETCH_FIREBASE_USER = 'FETCH_FIREBASE_USER';
export const UPDATE_FIREBASE_USER = 'UPDATE_FIREBASE_USER';
export const CHANGE_FIREBASE_USER_PASSWORD = 'CHANGE_FIREBASE_USER_PASSWORD';
export const FIREBASE_PASSWORD_RESET_EMAIL = 'FIREBASE_PASSWORD_RESET_EMAIL';
export const LOGOUT_FIREBASE_USER = 'LOGOUT_FIREBASE_USER';

// ON LAYOUT BUILDER
export const DELETE_CELL = 'DELETE_CELL';
