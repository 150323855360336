import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import 'sweetalert/dist/sweetalert.css';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
    loadUser, updateUser, updatePassword, saveProfile,
} from './actions';
import Loading from '../helpers/loading';
import { firestore } from '../../utils/firebase';

class UserEdit extends Component {
    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onFormPasswordSubmit = this.onFormPasswordSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.state = {
            userData: {
                email: '',
                firstname: '',
                lastname: '',
            },
            isActive: false,
            profileList: [],
            loading: false,
        };
    }

    componentWillMount() {
        this.loadProfiles();
        this.loadUser();
    }

    onFormSubmit(event) {
        event.preventDefault();
        const { userData } = this.state;
        this.props.updateUser(this.state.userData).then((data) => {
            if (!data.success) {
                swal({
                    title: 'Cannot Update User!',
                    text: data.message,
                    type: 'error',
                });
            } else {
                firestore.collection('users').doc(userData.uid).update({
                    ...userData,
                });
            }
        });
    }

    onFormPasswordSubmit(event) {
        event.preventDefault();
        const password = this.refs.password.value;
        const repassword = this.refs.repassword.value;
        const uid = this.props.params.id;
        if (password !== repassword) {
            swal({
                title: 'Error!',
                text: 'Password not match',
                type: 'error',
            });
        } else {
            this.props.updatePassword({ uid, password }).then((data) => {
                if (!data.success) {
                    swal({
                        title: 'Cannot Update Password!',
                        text: data.message,
                        type: 'error',
                    });
                } else {
                    browserHistory.push('/user');
                }
            });
        }
    }

    async loadUser() {
        const query = this.props.params;
        this.setState({ loading: true });
        try {
            const user = await firestore.collection('users').doc(query.id).get();
            this.setState({
                loading: false,
                userData: {
                    ...user.data(),
                    uid: user.id,
                    firebaseKey: user.id,
                },
            });
        } catch (error) {
            swal('An error occured', error.message, 'error');
            this.setState({ loading: false });
        }
    }

    handleChange(evt) {
        const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
        const target = `{ "${evt.target.id}":"${value}" }`;
        const update = require('react-addons-update');
        let newData = update(this.state.userData, { $merge: JSON.parse(target) });

        if (evt.target.id === 'profile') {
            const profile = this.state.profileList;
            const found = profile.find((e) => evt.target.value === e.id);
            const targetRole = `{ "role":"${found.data.Role}" }`;
            newData = update(newData, { $merge: JSON.parse(targetRole) });
        }
        this.setState({ userData: newData });
    }

    handleCheck(evt) {
        const { userData } = this.state;
        this.setState({
            userData: {
                ...userData,
                [evt.target.id]: evt.target.checked,
            },
        });
    }

    async loadProfiles() {
        const profileList = [{
            id: '',
            data: '',
        }];
        await firestore.collection('Profiles').get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    const { id } = doc;
                    profileList.push({ id, data });
                });
                this.setState({ profileList });
            })
            .catch((err) => {
                console.log('Error getting documents', err);
            });
    }

    render() {
        // console.log(this.state.userData);
        const { loading, userData } = this.state;
        if (loading) {
            return <Loading />;
        }
        return (
            <div>
                <section className="content-header">
                    <h1>Update User</h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/">
                                <i className="fa fa-dashboard" />
                                {' '}
                                Home
                            </Link>
                        </li>
                        <li><Link to="/user"> User</Link></li>
                        <li className="active"> Update</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="box box-primary">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Update Profile</h3>
                                </div>
                                <form id="frmUpdateProfile" role="form" onSubmit={this.onFormSubmit}>
                                    <div className="box-body">
                                        <div className="form-group required">
                                            <label htmlFor="email">Email address</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                ref="email"
                                                id="email"
                                                placeholder="Enter email"
                                                name="email"
                                                required
                                                onChange={this.handleChange}
                                                value={userData.email}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="firstname">First Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                ref="firstname"
                                                id="firstname"
                                                placeholder="First Name"
                                                name="firstname"
                                                onChange={this.handleChange}
                                                value={userData.firstname}
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="lastname">Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                ref="lastname"
                                                id="lastname"
                                                placeholder="Last Name"
                                                name="lastname"
                                                required
                                                onChange={this.handleChange}
                                                value={this.state.userData.lastname}
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="txtRegPass">Profile</label>
                                            <select name="profile" ref="profile" id="profile" className="form-control" value={this.state.userData.profile} onChange={this.handleChange} required>
                                                {this.state.profileList.map((e, key) => <option key={key} value={e.id}>{e.data.ProfileName}</option>)}
                                            </select>
                                        </div>
                                        <div className="checkbox">
                                            <label htmlFor="active">
                                                <input name="active" id="active" value="true" type="checkbox" onChange={this.handleCheck} checked={userData.active} />
                                                {' '}
                                                Active
                                            </label>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <Link
                                            to="/user"
                                            className="btn btn-default"
                                            type="button"
                                        >
                                            Cancel
                                        </Link>
                                        <button type="submit" className="btn btn-info pull-right" disabled={loading}>Update Profile</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="box box-primary">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Update Password</h3>
                                </div>
                                <form id="frmUpdatePassword" role="form" onSubmit={this.onFormPasswordSubmit}>
                                    <div className="box-body">
                                        <div className="form-group required">
                                            <label htmlFor="password">Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                ref="password"
                                                id="password"
                                                placeholder="Password"
                                                name="password"
                                                required
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="password">Repeat Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                ref="repassword"
                                                id="repassword"
                                                placeholder="Repeat Password"
                                                name="repassword"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <Link
                                            to="/user"
                                            className="btn btn-default"
                                            type="button"
                                        >
                                            Cancel
                                        </Link>
                                        <button type="submit" className="btn btn-info pull-right" disabled={this.props.common.isLoading}>Update Password</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        loadUser,
        saveProfile,
        updateUser,
        updatePassword,
    }, dispatch);
}

function mapStateToProps(state) {
    return { admin: state.admin, common: state.common };
}

UserEdit.propTypes = {
    admin: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    loadUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
