import FirebaseTools, { firebaseDb, firestore } from './firebase';

const key = Object.keys(localStorage).find((e) => e.match(/firebase:authUser/));
const data = JSON.parse(localStorage.getItem(key));

export function getToken() {
    return data.accessToken;
}

export function loggedIn() {
    return data || false;
}

export async function isAdmin() {
    const currentUser = await FirebaseTools.fetchUser();
    const ref = firebaseDb.ref(`/users/${currentUser.uid}/`);
    const snapshot = await ref.once('value');
    let userData = snapshot.val();
    if (!userData) {
        userData = await firestore.collection('users').doc(currentUser.uid).get();
        userData = userData.data();
    }
    if (userData) {
        return userData.role === 'admin';
    }
    return false;
}

export default null;
