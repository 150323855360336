import firebase from 'firebase/app';
import { firebaseDb, firebaseStorage } from '../../utils/firebase';

let CONFIG = null;
if (__DEV__) CONFIG = require('../../config.dev');
else CONFIG = require('../../config');

export const createNewUser = (data) => ({
    type: 'NEW_USER',
    payload: data,
});

export const loadAllUser = () => (
    async (dispatch, getState) => {
        const ref = firebaseDb.ref('/users/').orderByChild('email').limitToFirst(100);
        const snapshot = await ref.once('value');
        const data = snapshot.val();
        const newDataStructure = [];
        // const thisUser = await getState().currentUser;
        // delete data[thisUser.uid];
        for (const i in data) {
            if (data[i].role === 'sales') {
                data[i].role = 'Mobile - Sales';
            } else if (data[i].role === 'dm') {
                data[i].role = 'Mobile - DM';
            }
            newDataStructure.push({
                ...data[i],
                firebaseKey: i,
            });
        }
        dispatch({
            type: 'ALL_USER_FROM_FIREBASE',
            payload: newDataStructure,
        });
    }
);

export const saveProfile = (user) => (
    async (dispatch) => {
        // const admin = getState().admin;
        const node = {
            firstname: user.firstname || '',
            lastname: user.lastname || '',
            email: user.email || '',
            active: user.active || '',
            role: user.role || '',
            profile: user.profile || '',
        };
        await firebaseDb.ref(`/users/${user.uid}/`).set(node);
        dispatch({
            type: 'TOGGLE_LOADING',
        });
    }
);

export function createUser(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: 'TOGGLE_LOADING',
        });
        const token = await getState().currentUser.getIdToken();
        const url = `${CONFIG.FIREBASE_CONFIG.cloudFunctionUrl}userApi/register`;
        return fetch(url, {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
                mode: 'cors',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: data.email,
                password: data.password,
                lastname: data.lastname,
                firstname: data.firstname,
                role: data.role,
                active: data.active,
            }),
            cache: 'default',
        }).then((response) => response.json()).then((json) => json);
        /*
        .then(json => ({
            type: 'NEW_USER',
            payload: json,
        }));
        */
    };
}

export function updateUser(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: 'TOGGLE_LOADING',
        });
        const token = await getState().currentUser.getIdToken();
        const url = `${CONFIG.FIREBASE_CONFIG.cloudFunctionUrl}userApi/update`;
        return fetch(url, {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
                mode: 'cors',
            },
            body: JSON.stringify(data),
            cache: 'default',
        }).then((response) => response.json()).then((json) => json);
    };
}

export function updatePassword(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: 'TOGGLE_LOADING',
        });
        const token = await getState().currentUser.getIdToken();
        const url = `${CONFIG.FIREBASE_CONFIG.cloudFunctionUrl}userApi/change-password`;
        return fetch(url, {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
                mode: 'cors',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            cache: 'default',
        }).then((response) => response.json()).then((json) => json);
    };
}

export function viewUser(data) {
    return async (dispatch, getState) => {
        const token = await getState().currentUser.getIdToken();
        const url = `${CONFIG.FIREBASE_CONFIG.cloudFunctionUrl}userApi/view`;
        return fetch(url, {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
                mode: 'cors',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uid: data.uid,
            }),
            cache: 'default',
        }).then((response) => response.json()).then((json) => {
            dispatch({
                type: 'GET_USER',
                payload: json,
            });
            return json;
        });
    };
}

export function loadUser(id) {
    return async (dispatch, getState) => {
        dispatch({
            type: 'TOGGLE_LOADING',
        });
        const ref = firebaseDb.ref(`/users/${id}/`);
        const snapshot = await ref.once('value');
        const data = snapshot.val();
        dispatch({
            type: 'TOGGLE_LOADING',
        });
        data.uid = id;
        // data.push(key);
        dispatch({
            type: 'GET_USER',
            payload: data,
        });
        return data;
    };
}

export function loadProfileType() {
    return async (dispatch, getState) => {
        const ref = firebaseDb.ref('/roles/').limitToFirst(100);
        const snapshot = await ref.once('value');
        const data = snapshot.val();
        delete data.admin;
        // data.push(key);
        dispatch({
            type: 'ALL_ACL_FROM_FIREBASE',
            payload: data,
        });
        return data;
    };
}

export function updateProfileType(acl) {
    return async (dispatch, getState) => {
        dispatch({
            type: 'TOGGLE_LOADING',
        });
        await firebaseDb.ref('/roles/dm/').set(acl.aclDm);
        await firebaseDb.ref('/roles/management/').set(acl.aclManagement);
        await firebaseDb.ref('/roles/sales/').set(acl.aclSales);
        dispatch({
            type: 'TOGGLE_LOADING',
        });
        return true;
    };
}

export default '';
