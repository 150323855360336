import React, { Component } from 'react';
import LayoutBuilder from './layout_builder';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (<div className="">
            <section className="content-header">
                <div className={'col-md-12'}>
                    <h2>Property Management</h2>
                </div>
                <hr />
            </section>
            <div className="col-lg-12 col-sm-12" style={{ marginTop: 20 }}>
                <p>This page intended to be blank.</p>
            </div>
        </div>);
    }
}
