import React from 'react';
import { findSelectedCell } from '../../utils/common';

export default class AttributModal extends React.Component {
    componentDidMount() {
        window.onkeydown = (e) => {
            if (e.keyCode === 84 && e.ctrlKey) {
                e.preventDefault();
                this.props.undo();
            }
        };
    }

    componentWillUnmount() {
        window.onkeydown = null;
        $('[data-toggle="tooltip"]').tooltip();
    }

    render() {
        const { props } = this;
        const selectedCell = findSelectedCell(props.layoutBuilderData);
        const forms = [];
        const shownAttribute = ['width', 'height', 'left', 'bottom', 'label'];
        if (selectedCell.length > 1) {
            // shownAttribute.splice(3, 2);
        }
        if (selectedCell.length > 0) {
            shownAttribute.map(attr => (
                forms.push(
                    <div className="form-group" key={`form${attr}`}>
                        <label htmlFor={attr} className="col-sm-4">{attr}</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={typeof selectedCell[0][attr] === 'number' ? selectedCell[0][attr].toFixed(2) : selectedCell[0][attr]}
                                name={attr}
                                className="form-control"
                                onChangeCapture={e => props.changeAttribute(attr, e.target.value)}
                            />
                        </div>
                    </div>
                )
            ));
        }

        let productLinkButton = '';
        if (selectedCell.length === 1) {
            if (selectedCell[0].product !== undefined && selectedCell[0].product !== null) {
                productLinkButton = <button className="btn btn-danger" onClick={this.props.unlinkProduct}>({selectedCell[0].product.Name})<i className="fa fa-unlink" /></button>;
            } else {
                productLinkButton = <button className="btn btn-primary" data-toggle="modal" data-target="#findProductModal"><i className="fa fa-link" /></button>;
            }
        }

        return (
            <div className="modalAttribute attributeInfo" ref={props.modalRef}>
                <div className="header">Cell Attribute</div>
                <div className="content">
                    <p>{selectedCell.length} cell(s) selected. </p>
                    <form className="form-horizontal">
                        {forms}
                    </form>
                    
                    <div className="button-place text-center">
                        {productLinkButton}
                        <button
                            className="btn btn-default"
                            onClick={() => props.toggleSelectAll(selectedCell.length === 0)}
                            title={selectedCell.length > 0 ? 'Select All' : 'Unselect All'}
                            data-toggle="tooltip"
                            data-placement="top"
                        >
                            <i className={`fa fa-object-${selectedCell.length > 0 ? 'group' : 'ungroup'}`} />
                        </button>
                        <button
                            className="btn btn-warning"
                            onClick={() => props.undo()}
                            data-toggle="tooltip"
                            title="Undo"
                            data-placement="top"
                        >
                            <i className="fa fa-undo" />
                        </button>
                        <button
                            className="btn btn-default"
                            onClick={() => props.toggleAlign()}
                            data-toggle="tooltip"
                            title="Toggle text align"
                            data-placement="top"
                        >
                            <i className="fa fa-align-center" />
                        </button>
                        <button
                            className="btn btn-default"
                            onClick={() => props.addCell(props.imageHeight)}
                            data-toggle="tooltip"
                            title="Add Cell"
                            data-placement="top"
                        >
                            <i className="fa fa-plus" />
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={() => props.deleteCell()}
                            data-toggle="tooltip"
                            title="Toggle text align"
                            data-placement="top"
                        >
                            <i className="fa fa-trash" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
