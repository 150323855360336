import React from 'react';


const Loading = () => {
    return (
    <div className="col-md-4">
      Loading ....
    </div>
  );
};

export default Loading;
