import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import swal from 'sweetalert';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import 'react-bootstrap-table/css/toastr.css';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadAllUser } from './actions';
import { firestore } from '../../utils/firebase';

function actionButton(cell) {
    // const viewButton = <Link className="btn btn-success btn-xs text-center" type="button" to={'/user/view/' + cell}><i className="fa fa-eye" /> View</Link>;
    const editButton = (
        <Link className="btn btn-warning btn-xs text-center" type="button" to={`/user/edit/${cell}`}>
            <i className="fa fa-pencil" />
            {' '}
            Update
        </Link>
    );
    return <ButtonGroup>{editButton}</ButtonGroup>;
}

function setActive(cell) {
    if (cell) {
        return '<i aria-hidden="true" class="fa fa-check" />';
    }
    return '<i aria-hidden="true" class="fa fa-close" />';
}

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            allUser: [],
            loading: false,
        };
        this.loadProfiles = this.loadProfiles.bind(this);
    }

    componentWillMount() {
        this.loadAllUser();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.admin.allUser !== this.props.admin.allUser) {
            this.loadProfiles(nextProps.admin.allUser);
        }
    }

    async loadAllUser() {
        this.setState({ loading: true });
        try {
            const users = await firestore.collection('users').limit(200).get();
            const allUser = [];
            users.forEach((user) => allUser.push({
                ...user.data(),
                id: user.id,
            }));
            this.setState({ loading: false, allUser });
        } catch (error) {
            swal({
                text: error.message,
                title: 'An error occured',
                type: 'error',
            });
            this.setState({ loading: false });
        }
    }

    async loadProfiles(allUser) {
        const allProfile = {};
        await firestore.collection('Profiles').get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    const { id } = doc;
                    allProfile[id] = data;
                });
            })
            .then(() => {
                allUser.forEach((e) => {
                    if (typeof e.profile !== 'undefined') {
                        if (typeof allProfile[e.profile] !== 'undefined') {
                            e.profileName = allProfile[e.profile].ProfileName;
                        } else {
                            e.profileName = '';
                        }
                    } else {
                        e.profileName = '';
                    }
                });
                this.setState({ allUser });
            })
            .catch((err) => {
                console.log('Error getting documents', err);
            });
    }

    formatName(row) {
        const fname = row.firstname ? `, ${row.firstname}` : '';
        return `${row.lastname}${fname} `;
    }

    render() {
        const { loading } = this.state;
        return this.props.children ? this.props.children : (
            <div>
                <section className="content-header">
                    <h1>User List</h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/">
                                <i className="fa fa-dashboard" />
                                {' '}
                                Home
                            </Link>
                        </li>
                        <li className="active">User</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-4 col-md-offset-4 text-center" style={{ marginBottom: 30 }}>
                            <Link className="btn btn-success text-center" type="button" to="/user/new">
                                <i className="fa fa-user-plus" />
                                {' '}
                                New User
                            </Link>
                            <div className="clearfix" />
                            {loading ? <i className="fa fa-spinner fa-pulse fa-5x fa-fw" /> : null}
                        </div>
                        <div className="clearfix" />
                        <div className="col-lg-12 col-xs-12">
                            <BootstrapTable
                                bordered
                                condensed
                                striped
                                hover
                                search
                                data={this.state.allUser}
                            >
                                <TableHeaderColumn
                                    isKey
                                    dataField="id"
                                    width="85"
                                    dataFormat={actionButton}
                                />
                                <TableHeaderColumn dataField="lastname" dataSort dataFormat={(cell, row) => this.formatName(row)}>Name</TableHeaderColumn>
                                <TableHeaderColumn dataField="email" dataSort>Email</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="profileName"
                                    dataSort
                                    dataFormat={(cell, row) => {
                                        if (cell === '' || cell === null || cell === undefined) {
                                            return row.profile;
                                        }
                                        return cell;
                                    }}
                                >
                                    Profile Name

                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="active" dataSort dataFormat={setActive} dataAlign="center">Active</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        admin: state.admin,
    };
}

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        loadAllUser,
    }, dispatch)
);

UserList.propTypes = {
    admin: PropTypes.object.isRequired,
    loadAllUser: PropTypes.func.isRequired,
    children: PropTypes.object,
};

UserList.defaultProps = {
    children: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
