import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import 'sweetalert/dist/sweetalert.css';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { loadProfileType, updateProfileType } from './actions';
import Loading from '../helpers/loading';

class UserControl extends Component {
    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            aclManagement: {},
            aclDm: {},
            aclSales: {},
        };
    }

    componentWillMount() {
        this.props.loadProfileType().then((data) => {
            this.setState({ aclDm: data.dm });
            this.setState({ aclManagement: data.management });
            this.setState({ aclSales: data.sales });
        });
    }

    onFormSubmit(event) {
        event.preventDefault();
        this.props.updateProfileType({ aclManagement: this.state.aclManagement, aclSales: this.state.aclSales, aclDm: this.state.aclDm }).then((data) => {
            if (!data) {
                swal({
                    title: 'Cannot Update Profile!',
                    text: data.message,
                    type: 'error',
                });
            } else {
                swal({
                    title: 'Profile Saved!',
                    text: data.message,
                    type: 'success',
                });
            }
        });
    }
    handleChange(evt) {
        const name = evt.target.name;
        const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
        const target = `{ "${evt.target.id}":"${value}" }`;
        const update = require('react-addons-update');
        if (name === 'management') {
            const newData = update(this.state.aclManagement, { $merge: JSON.parse(target) });
            this.setState({ aclManagement: newData });
        } else if (name === 'dm') {
            const newData = update(this.state.aclDm, { $merge: JSON.parse(target) });
            this.setState({ aclDm: newData });
        } else {
            const newData = update(this.state.aclSales, { $merge: JSON.parse(target) });
            this.setState({ aclSales: newData });
        }
    }

    render() {
        return (
            <div>
                <section className="content-header">
                    <h1>Access Control</h1>
                    <ol className="breadcrumb">
                        <li><Link to={'/'}><i className="fa fa-dashboard" /> Home</Link></li>
                        <li className="active"> ACL</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <form id="frmACL" role="form" onSubmit={this.onFormSubmit}>
                                <table className="table table-hover">
                                    <thead>
                                        <tr className="active">
                                            <th>Access Type</th>
                                            <th>Title Deed Area</th>
                                            <th>Title Deed Price</th>
                                            <th>Room Type</th>
                                            <th>Price Per Square</th>
                                            <th>Sales Status</th>
                                            <th>Transfer Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="active">Management</td>
                                            <td>
                                                <select name="management" ref="mtda" id="tda" className="form-control" value={this.state.aclManagement.tda} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="management" ref="mtpd" id="tpd" className="form-control" value={this.state.aclManagement.tpd} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="management" ref="mrt" id="rt" className="form-control" value={this.state.aclManagement.rt} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="management" ref="mpps" id="pps" className="form-control" value={this.state.aclManagement.pps} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="management" ref="mss" id="ss" className="form-control" value={this.state.aclManagement.ss} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="management" ref="mts" id="ts" className="form-control" value={this.state.aclManagement.ts} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="active">Mobile - DM</td>
                                            <td>
                                                <select name="dm" ref="dmtda" id="tda" className="form-control" value={this.state.aclDm.tda} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="dm" ref="dmtpd" id="tpd" className="form-control" value={this.state.aclDm.tpd} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="dm" ref="dmrt" id="rt" className="form-control" value={this.state.aclDm.rt} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="dm" ref="dmpps" id="pps" className="form-control" value={this.state.aclDm.pps} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="dm" ref="dmss" id="ss" className="form-control" value={this.state.aclDm.ss} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="dm" ref="dmts" id="ts" className="form-control" value={this.state.aclDm.ts} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="active">Mobile - Sales</td>
                                            <td>
                                                <select name="sales" ref="stda" id="tda" className="form-control" value={this.state.aclSales.tda} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="sales" ref="stpd" id="tpd" className="form-control" value={this.state.aclSales.tpd} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="sales" ref="srt" id="rt" className="form-control" value={this.state.aclSales.rt} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="sales" ref="spps" id="pps" className="form-control" value={this.state.aclSales.pps} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="sales" ref="sss" id="ss" className="form-control" value={this.state.aclSales.ss} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select name="sales" ref="sts" id="ts" className="form-control" value={this.state.aclSales.ts} onChange={this.handleChange} required>
                                                    <option key="" value="">- Select -</option>
                                                    <option key="edit" value="edit">Edit</option>
                                                    <option key="read" value="read">Read Only</option>
                                                    <option key="none" value="none">Not Visible</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="7">
                                                <center>
                                                    <button type="submit" className="btn btn-info" disabled={this.props.common.isLoading} >Update  Profile</button>
                                                </center>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        loadProfileType,
        updateProfileType,
    }, dispatch);
}

function mapStateToProps(state) {
    return { admin: state.admin, common: state.common };
}

UserControl.propTypes = {
    admin: PropTypes.object.isRequired,
    loadProfileType: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserControl);
