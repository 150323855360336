import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/auth';

let config = null;

if (__DEV__ && window.location.hostname !== 'localhost') config = require('../config.dev');
else config = require('../config');

if (firebase.apps.length === 0) {
    firebase.initializeApp(config.FIREBASE_CONFIG);
}
export const firebaseApp = firebase.apps[0];
export const firebaseAuth = firebase.auth();
export const firebaseDb = firebase.database();
export const firebaseStorage = firebase.storage();
export const firestore = firebase.firestore();

export { firebase };

if (window.location.hostname === 'localhost') {
    firebase.firestore().settings({
        host: 'localhost:8080',
        ssl: false,
    });
}

const FireBaseTools = {

    /**
   * Return an instance of a firebase auth provider based on the provider string.
   *
   * @param provider
   * @returns {firebase.auth.AuthProvider}
   */
    getProvider: (provider) => {
        switch (provider) {
            case 'email':
                return new firebase.auth.EmailAuthProvider();
            case 'facebook':
                return new firebase.auth.FacebookAuthProvider();
            case 'github':
                return new firebase.auth.GithubAuthProvider();
            case 'google':
                return new firebase.auth.GoogleAuthProvider();
            case 'twitter':
                return new firebase.auth.TwitterAuthProvider();
            default:
                throw new Error('Provider is not supported!!!');
        }
    },

    /**
   * Login with provider => p is provider "email", "facebook", "github", "google", or "twitter"
   * Uses Popup therefore provider must be an OAuth provider. EmailAuthProvider will throw an error
   *
   * @returns {any|!firebase.Thenable.<*>|firebase.Thenable<any>}
   */
    loginWithProvider: (p) => {
        const provider = FireBaseTools.getProvider(p);
        return firebaseAuth.signInWithPopup(provider).then(firebaseAuth.currentUser).catch((error) => ({
            errorCode: error.code,
            errorMessage: error.message,
        }));
    },

    /**
   * Register a user with email and password
   *
   * @param user
   * @returns {any|!firebase.Thenable.<*>|firebase.Thenable<any>}
   */
    registerUser: (user) => firebaseAuth.createUserWithEmailAndPassword(user.email, user.password)
        .then((userInfo) => userInfo)
        .catch((error) => ({
            errorCode: error.code,
            errorMessage: error.message,
        })),

    /**
   * Sign the user out
   *
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
    logoutUser: () => firebaseAuth.signOut().then(() => {
        localStorage.clear();
        window.location.reload();
    }),

    /**
   * Retrieve the current user (Promise)
   * @returns {Promise}
   */
    fetchUser: () => new Promise((resolve, reject) => {
        const unsub = firebaseAuth.onAuthStateChanged((user) => {
            unsub();
            resolve(user);
        }, (error) => {
            reject(error);
        });
    }),

    /**
   * Log the user in using email and password
   *
   * @param user
   * @returns {any|!firebase.Thenable.<*>|firebase.Thenable<any>}
   */
    loginUser: (u) => firebaseAuth.signInWithEmailAndPassword(u.email, u.password).then((user) => ({
        success: true,
        ...user.user,
    }), (error) => ({
        success: false,
        errorCode: error.code,
        errorMessage: error.message,
    })),

    /**
   * Update a user's profile data
   *
   * @param u
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
    updateUserProfile: (u) => firebaseAuth.currentUser.updateProfile(u).then(() => firebaseAuth.currentUser, (error) => ({
        errorCode: error.code,
        errorMessage: error.message,
    })),

    /**
   * Reset the password given the specified email
   *
   * @param email {string}
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
    resetPasswordEmail: (email) => firebaseAuth.sendPasswordResetEmail(email).then(() => ({
        message: 'Email sent',
    }), (error) => ({
        errorCode: error.code,
        errorMessage: error.message,
    })),

    /**
   * Update the user's password with the given password
   *
   * @param newPassword {string}
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
    changePassword: (newPassword) => firebaseAuth.currentUser.updatePassword(newPassword).then((user) => user, (error) => ({
        errorCode: error.code,
        errorMessage: error.message,
    })),

    /**
   * Send an account email verification message for the currently logged in user
   *
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
    sendEmailVerification: () => firebaseAuth.currentUser.sendEmailVerification().then(() => ({
        message: 'Email sent',
    }), (error) => ({
        errorCode: error.code,
        errorMessage: error.message,
    })),

    /**
   * Get the firebase database reference.
   *
   * @param path {!string|string}
   * @returns {!firebase.database.Reference|firebase.database.Reference}
   */
    getDatabaseReference: (path) => firebaseDb.ref(path),

    getToken: () => firebaseAuth.currentUser.getToken(true).then((tokenId) => ({
        token: tokenId,
    }), (error) => ({
        errorCode: error.code,
        errorMessage: error.message,
    })),
};

export default FireBaseTools;
