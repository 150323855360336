import 'firebase/app';
import $ from 'jquery';
import moment from 'moment';
import { sfRequest, getBase64FromImageUrl } from '../../utils/common';
import { firebaseDb, firebaseStorage, firestore } from '../../utils/firebase';
import { loadAllLayout } from '../layoutbuilder/actions';

export const getApiProductField = () => (dispatch, getState) => {
    const salesforce = getState().salesforce.token;
    sfRequest('Product2', {
        id: 'describe',
        method: 'GET',
        url: salesforce.instanceUrl,
        accessToken: salesforce.accessToken,
        headers: {
            mode: 'cors',
        },
    }, dispatch, 'PRODUCT_DESCRIBED');
};
export { loadAllLayout };

export const createNewQuery = (layout) => ({
    type: 'NEW_QUERY',
    payload: layout,
});

export const loadAllQuery = () => (
    async (dispatch) => {
        const queries = await firestore.collection('query').limit(200).get();
        const newDataStructure = [];
        queries.forEach((query) => {
            newDataStructure.push({
                ...query.data(),
                firebaseKey: query.id,
            });
        });

        dispatch({
            type: 'ALL_QUERY_FROM_FIREBASE',
            payload: newDataStructure,
        });
    });

export const stopListeningQueryRef = () => ({
    type: 'STOP_LISTENING_QUERY_REF',
});
export const loadOneLayout = (key, queryKey, callback) => (
    async (dispatch, getState) => {
        const { allLayout } = getState().layoutBuilder;

        if (allLayout.length === 0) {
            let layout = await firebaseDb.ref(`/layout/${key}`).once('value');
            layout = layout.val();
            allLayout.push({
                ...layout,
                firebaseKey: key,
            });
        }
        let selectedIndex = 0;
        for (let x = 0; x < allLayout.length; x++) {
            if (key === allLayout[x].firebaseKey) selectedIndex = x;
        }
        const url = await firebaseStorage.ref(allLayout[selectedIndex].layoutImage).getDownloadURL();
        const base64Image = await getBase64FromImageUrl(url);
        let node = {};
        if (queryKey) {
            const ref = firebaseDb.ref(`/query/${queryKey}`);
            const snapshot = await ref.once('value');
            node = snapshot.val();
        }
        dispatch({
            type: 'QUERY_LAYOUT',
            payload: {
                firebaseKey: key,
                layoutImage: base64Image,
                layoutBuilder: allLayout[selectedIndex],
                queryFirebaseKey: queryKey,
                node,
            },
        });
        if (typeof callback === 'function') callback();
    }
);

export const loadSfProduct = (key) => (
    (dispatch, getState) => {
        const query = `Select Id, Name, Price__c, Transfer_Status__c, Room_Type__c, Quota__c,
        Unit_Status__c, Sales_Status__c, Detail_Room__c, Resale_Status__c, Actual_Area__c from Product2 where Project_Id__c = '${key}'`;
        const salesforceToken = getState().salesforce.token;
        sfRequest(null, {
            method: 'GET',
            url: salesforceToken.instanceUrl,
            query,
            accessToken: salesforceToken.accessToken,
        }, dispatch, 'PRODUCT_BY_PROJECT');
    }
);

export const getAllProductField = () => (
    (dispatch, getState) => {
        const salesforceToken = getState().salesforce.token;
        const { productField } = getState().salesforce;
        if (new Date().getTime() - productField.retrievedAt > 60 * 60 * 1000) {
            sfRequest('Product2', {
                method: 'GET',
                url: salesforceToken.instanceUrl,
                id: 'describe',
                accessToken: salesforceToken.accessToken,
            }, ({ type, payload }) => {
                if (type === 'PRODUCT_FIELD') {
                    const { fields } = payload;
                    fields.sort((a, b) => {
                        if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
                        if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
                        return 0;
                    });
                    dispatch({ type, payload: fields });
                } else {
                    dispatch({ type, payload });
                }
            }, 'PRODUCT_FIELD');
        }
    }
);

export const applyFilter = (filterForm, filterName) => (
    async (dispatch, getState) => {
        dispatch({
            type: 'TOGGLE_LOADING_FILTER',
        });
        dispatch({
            type: 'FILTER_FORM',
            payload: {
                filterForm,
                filterName,
            },
        });
        const salesforceToken = getState().salesforce.token;
        const projectId = getState().buildingQuery.layoutProject.Id;
        const queries = [];
        const response = [];
        for (let i = 0; i < filterForm.length; i++) {
            let eachQuery = `Select Id, Name, Price__c, Transfer_Status__c, Quota__c, Room_Type__c, Price_Per_Square__c,
                Unit_Status__c, Sales_Status__c, Detail_Room__c, Resale_Status__c, Actual_Area__c from Product2 where  Project_Id__c = '${projectId}'`;
            Object.keys(filterForm[i]).forEach((obj) => {
                if (typeof filterForm[i][obj] === 'object') {
                    if (filterForm[i][obj].field !== '') {
                        eachQuery += ` AND ${filterForm[i][obj].field} ${filterForm[i][obj].operator} `;
                        if (filterForm[i][obj].dataType === 'datetime') {
                            eachQuery += moment(filterForm[i][obj].value).toISOString();
                        } else if (filterForm[i][obj].dataType === 'date') {
                            eachQuery += moment(filterForm[i][obj].value).format('YYYY-MM-DD');
                        } else {
                            eachQuery += filterForm[i][obj].dataType === 'double' || filterForm[i][obj].dataType === 'currency'
                                || filterForm[i][obj].dataType === 'boolean' ? filterForm[i][obj].value : `'${filterForm[i][obj].value}'`;
                        }
                    }
                }
            });
            queries.push(eachQuery);
        }
        await Promise.all(queries.map(async (query, index) => {
            response[index] = await fetch(
                `${salesforceToken.instanceUrl}/services/data/v40.0/query/?q=${encodeURIComponent(query)}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${salesforceToken.accessToken}`,
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                    },
                    timeout: 5000,
                },
            ).then((rsp) => rsp.json());
        }));
        dispatch({
            type: 'DATA_FILTER',
            payload: {
                ...response,
            },
        });

        dispatch({
            type: 'TOGGLE_LOADING_FILTER',
        });
    }
);

export const saveQuery = (filterForm, name, buildingKey) => (
    (dispatch, getState) => {
        applyFilter(filterForm);
        const { currentUser } = getState();
        const { buildingQuery } = getState();

        dispatch({
            type: 'QUERY_SAVING',
        });
        for (let i = 0; i < filterForm.length; i++) {
            Object.keys(filterForm[i]).forEach((val, index) => {
                if (filterForm[i][index]) {
                    if (filterForm[i][index].dataType === 'date' || filterForm[i][index].dataType === 'datetime') {
                        filterForm[i][index].value = moment(filterForm[i][index].value).format('YYYY-MM-DD');
                    }
                }
            });
        }
        const newPostKey = buildingQuery.queryFirebaseKey === undefined ? firebaseDb.ref('/query').push().key : buildingQuery.queryFirebaseKey;
        const node = {
            buildingName: buildingQuery.layoutBuildingName,
            projectName: buildingQuery.layoutProject.Name,
            projectId: buildingQuery.layoutProject.Id,
            filterForm,
            name,
            buildingKey,
            lastModifiedDate: new Date().getTime(),
            createdBy: {
                name: currentUser.displayName,
                email: currentUser.email,
            },
            firebaseKey: newPostKey,
        };
        firebaseDb.ref(`/query/${newPostKey}/`).set(node).then(() => {
            dispatch({
                type: 'QUERY_SAVED',
                payload: node,
            });
            swal({
                title: 'Query saved.',
                text: 'This query succesfully saved.',
                type: 'success',
            });
        }).catch((err) => {
            swal({
                title: 'Failed to save.',
                text: `${err.message}`,
                type: 'warning',
            });
        });
    }
);

export const deleteQuery = (key) => {
    swal({
        title: 'Are you sure?',
        text: `You will not be able to recover this ${key} query file!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, delete it!',
        closeOnConfirm: false,
    },
        () => {
            firebaseDb.ref(`/query/${key}`).set(null).then(() => {
                swal('Deleted!', 'Your query has been deleted.', 'success');
            }).catch((err) => swal(err.message));
        });
    return {
        type: 'DELETE_QUERY',
    };
};

export default '';
