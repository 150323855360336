import React, { Component } from 'react';

class Error403 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
        };
    }
    render() {
        return (
            <div>
                
                <section className="content">
                    <div className="error-page">
                        <h2 className="headline text-red">403</h2>
                        <div className="error-content">
                            <h3><i className="fa fa-warning text-red" /> Access Denied.</h3>
                            <p>You are not authorized to access this page</p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

}

export default Error403;
