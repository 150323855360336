module.exports = {

    // Change this to your firebase configuration! (Add Firebase to your web app)
    FIREBASE_CONFIG: {
        apiKey: 'AIzaSyDA5HXb35FRnjBe42WuetcXQAY05rNaUuA',
        authDomain: 'raimon-land-sales-matrix.firebaseapp.com',
        databaseURL: 'https://raimon-land-sales-matrix.firebaseio.com',
        projectId: 'raimon-land-sales-matrix',
        storageBucket: 'raimon-land-sales-matrix.appspot.com',
        messagingSenderId: '1036281046422',
        cloudFunctionUrl: window.location.origin === 'localhost' ? 'http://localhost:5001/raimon-land-sales-matrix-prod/us-central1/'
            : 'https://us-central1-raimon-land-sales-matrix.cloudfunctions.net/',
    },
};
