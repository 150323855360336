import React from 'react';
import { Route } from 'react-router';
import App from './components/app';

import HomeIndex from './components/index_home';
import UserLogin from './components/user/login';
import UserLogout from './components/user/logout';
import UserRegister from './components/user/register';
import UserProfile from './components/user/profile';
import UserList from './components/admin/user_list';
import UserNew from './components/admin/user_new';
import UserEdit from './components/admin/user_edit';
import AccessControl from './components/admin/user_control';
import ResetPassword from './components/user/reset_password';
import BuildingList from './components/layoutbuilder/list';
import NewBuilding from './components/layoutbuilder/layout_builder';
import NewQuery from './components/query/newQuery';
import QueryList from './components/query';
import ProfilesList from './components/profiles';
import ProfileUpdate from './components/profiles/update';
import ProfilesNew from './components/profiles/new';
import Error404 from './components/error/404';
import Error403 from './components/error/403';
import { requireAuth, requireAdmin } from './utils/authenticated';

export default (
    <Route>
        <Route path="/login" component={UserLogin} />
        <Route path="/logout" component={UserLogout} />
        <Route path="/register" component={UserRegister} />
        <Route path="/reset" component={ResetPassword} />
        <Route component={App} onEnter={requireAuth}>
            <Route path="/" component={HomeIndex} onEnter={requireAuth} />
            <Route path="/query" component={QueryList} onEnter={requireAuth} />
            <Route path="/query/new/:id" component={NewQuery} onEnter={requireAuth} />
            <Route path="/query/edit/:id/:queryKey" component={NewQuery} onEnter={requireAuth} />
            <Route path="/profile" component={UserProfile} onEnter={requireAuth} />
            <Route path="/building" component={BuildingList} onEnter={requireAdmin} />
            <Route path="/building/new" component={NewBuilding} onEnter={requireAdmin} />
            <Route path="/building/edit/:key" component={NewBuilding} onEnter={requireAdmin} />
            <Route path="/user" component={UserList} onEnter={requireAdmin} />
            <Route path="/user/new" component={UserNew} onEnter={requireAdmin} />
            <Route path="/user/edit/:id" component={UserEdit} onEnter={requireAdmin} />
            <Route path="/acl" component={AccessControl} onEnter={requireAdmin} />
            <Route path="/profiles" component={ProfilesList} onEnter={requireAdmin} />
            <Route path="/profiles/update/:id" component={ProfileUpdate} onEnter={requireAdmin} />
            <Route path="/profiles/new" component={ProfilesNew} onEnter={requireAdmin} />
        </Route>
        <Route path="/forbidden" component={Error403} />
        <Route path="*" component={Error404} />
    </Route>
);
