import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import swal from 'sweetalert';
import 'sweetalert/dist/sweetalert.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, browserHistory } from 'react-router';
import Cell from './cell';
import {
    buildNewCell,
    changeImage,
    deleteCell,
    toggleCell,
    clearCell,
    changeAttribute,
    toggleSelectAll,
    undo,
    findProject,
    findAllProduct,
    pickProduct,
    pickProject,
    unlinkProduct,
    saveBuilding,
    changeBuildingName,
    changeImageRatio,
    loadOneLayout,
    selectManyCell,
    toggleAlign,
    changePublishStatus,
    unloadLayout,
    syncProductData,
    addCell,
} from './actions';
import { getUserToken } from '../../actions/firebase_actions';
import AttributeModal from './attribute_modal';
import FindProjectModal from './find_project';
import FindProductModal from './find_product';
import ProductInfoModal from './product_info_modal';
import ConfigModal from './config_modal';

class LayoutBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            imagePreviewUrl: '',
            imageWidth: 0,
            imageHeight: 0,
            imageRatio: 100,
            rows: 0,
            cols: 0,
            project: {},
            filteredProjects: [],
            all: [],
            syncDisabled: false,
        };
        this.attributeModal = {};
        this.productInfoModal = {};
        this._handleImageChange = this._handleImageChange.bind(this);
        this._apply = this._apply.bind(this);
        this._clear = this._clear.bind(this);
        this._pickProject = this._pickProject.bind(this);
        this._onRatioChange = this._onRatioChange.bind(this);
        this.filterProject = this.filterProject.bind(this);
        this.routeChange = {};
    }

    componentDidMount() {
        const firebaseKey = this.props.location.pathname.split('/');
        if (firebaseKey.length === 4) {
            this.props.loadOneLayout(firebaseKey[3]);
            $('#configModal').modal('hide');
        } else {
            $('#configModal').modal('show');
        }
    }

    componentWillReceiveProps(nextProps) {
        const { layoutImage } = nextProps.layoutBuilder;
        if (layoutImage !== null) {
            const image = new Image();
            image.onload = () => {
                this.setState({
                    imageWidth: image.width,
                    imageHeight: image.height,
                    imageRatio: nextProps.layoutBuilder.layoutImageRatio,
                });
            };
            image.src = layoutImage;
        }
        $(this.attributeModal).draggable({
            handle: '.header',
            containment: '.layout-builder',
            scroll: false,
        });
        $(this.productInfoModal).draggable({ handle: '.header', containment: '.layout-builder', scroll: false });
        this.filterProject();
        return true;
    }

    componentWillUnmount() {
        $('#configModal').modal('hide');
        unloadLayout();
    }

    componentDidUpdate() {
        this.filterProject();
        return true;
    }

    _pickProject(project) {
        this.setState({
            project,
        });
        this.props.findAllProduct(project.Id);
        this.props.pickProject(project);
    }

    filterProject() {
        if (this.props.layoutBuilder.allProject !== this.state.all) {
            this.setState({ all: this.props.layoutBuilder.allProject });
            const profile = this.props.profile.Projects;
            const all = this.props.layoutBuilder.allProject;

            const newAll = [];
            all.forEach((e) => {
                if (profile[e.Id] && profile[e.Id].enable === true) {
                    newAll.push(e);
                }
            });

            this.setState({ filteredProjects: newAll });
        }
    }

    _onRatioChange(e) {
        let value = parseInt(e.target.value, 10);
        if (isNaN(value)) value = 100;
        this.props.changeImageRatio(value);
    }

    _apply(rows, cols) {
        if (this.props.layoutBuilder.layoutBuilderData.length > 0) {
            swal({
                title: 'Are you sure?',
                text: 'This function is going to create new building layou, you will not be able to recover current layout.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                closeOnConfirm: true,
            }, () => {
                this.props.buildNewCell(rows, cols,
                    {
                        width: this.state.imageWidth * (this.props.layoutBuilder.layoutImageRatio / 100),
                        height: this.state.imageHeight * (this.props.layoutBuilder.layoutImageRatio / 100),
                    });
            });
        } else {
            this.props.buildNewCell(rows, cols,
                {
                    width: this.state.imageWidth * (this.props.layoutBuilder.layoutImageRatio / 100),
                    height: this.state.imageHeight * (this.props.layoutBuilder.layoutImageRatio / 100),
                });
        }
        $('#configModal').modal('hide');
    }

    _clear(e) {
        e.preventDefault();
        swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover current layout!',
            type: 'error',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes',
            closeOnConfirm: true,
        }, () => {
            this.props.clearCell();
        });
    }

    _handleImageChange(e) {
        e.preventDefault();

        const reader = new FileReader();
        const file = e.target.files[0];
        const image = new Image();
        image.onload = () => {
            this.setState({
                imageWidth: image.width,
                imageHeight: image.height,
            });
        };
        reader.onloadend = () => {
            this.setState({
                file,
                imagePreviewUrl: reader.result,
            });
            image.src = reader.result;
            this.props.changeImage(reader.result);
        };
        reader.readAsDataURL(file);
    }

    render() {
        const imagePreviewUrl = this.props.layoutBuilder.layoutImage;
        let $imagePreview = null;
        let notification = '';
        let loading = <div />;
        const { layoutBuilder, isLoading } = this.props;
        console.info(layoutBuilder);
        const isPubllished = (layoutBuilder.published !== undefined && layoutBuilder.published !== 'Unpublished');
        if (isLoading) {
            loading = (
                <div style={{ margin: '0 auto' }}>
                    <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }
        if (imagePreviewUrl && this.state.imageHeight > 0) {
            $imagePreview = (
                <div
                    className="builder-background"
                    style={{
                        height: this.state.imageHeight * (this.props.layoutBuilder.layoutImageRatio / 100),
                        width: this.state.imageWidth * (this.props.layoutBuilder.layoutImageRatio / 100),
                        background: `url(${imagePreviewUrl})  no-repeat center center`,
                        position: 'relative',
                        margin: '10px auto',
                    }}
                >
                    {_.times(this.props.layoutBuilder.layoutBuilderData.length, (floorNo) => {
                        const floors = [];
                        if (!this.props.layoutBuilder.layoutBuilderData[floorNo]) return floors;
                        const currentFloor = this.props.layoutBuilder.layoutBuilderData[floorNo];
                        Object.keys(currentFloor).forEach((i) => {
                            const unitData = this.props.layoutBuilder.layoutBuilderData[floorNo][i];
                            if (unitData !== null && unitData !== undefined) {
                                let { product } = unitData;
                                if (product) {
                                    product = this.props.layoutBuilder.layoutProductPicked[product.Id];
                                }
                                floors.push(
                                    <Cell
                                        bgColor="transparent"
                                        rowNo={unitData.rowNo}
                                        colNo={unitData.colNo}
                                        height={parseFloat(unitData.height)}
                                        width={parseFloat(unitData.width)}
                                        left={parseFloat(unitData.left)}
                                        bottom={parseFloat(unitData.bottom)}
                                        product={product}
                                        align={unitData.align}
                                        onDelete={this.props.deleteCell}
                                        onToggle={this.props.toggleCell}
                                        selected={unitData.selected}
                                        label={unitData.label}
                                        imageHeight={this.state.imageHeight * (this.state.imageRatio / 100)}
                                        imageRatio={this.state.imageRatio}
                                        onShiftClick={this.props.selectManyCell}
                                    />,
                                );
                            }
                        });
                        return floors;
                    })}
                </div>
            );
        }
        if (isPubllished) {
            notification = (
                <p style={{ textAlign: 'center', color: 'red' }}>
                    You're not allowed to edit
                    {' '}
                    {layoutBuilder.layouBuildingName}
                    .
                    Current publish status of this building is
                    {' '}
                    {layoutBuilder.published}
                    .
                </p>
            );
        }
        return (
            <div className="col-lg-12 col-sm-12">
                <h2>{layoutBuilder.layoutBuildingName === '' ? 'Layout Builder' : this.props.layoutBuilder.layoutBuildingName}</h2>
                <h4>{layoutBuilder.project ? layoutBuilder.project.Name : ''}</h4>
                <hr />
                {loading}
                <div className="layout-builder" style={{ position: 'relative' }}>
                    <div className="col-md-4 col-sm-4 col-md-offset-4 col-sm-offset-4">
                        <div className="btn-group ">
                            <Link
                                to="/building"
                                onClick={(e) => (this.props.layoutBuilder.isPending ? e.preventDefault() : '')}
                                className="btn btn-default btn-lg"
                                type="button"
                                disabled={this.props.layoutBuilder.isPending}
                            >
                                <i className="fa fa-arrow-left" />
                                {' '}
                                Cancel
                            </Link>
                            <button
                                className="btn btn-info btn-lg"
                                type="button"
                                data-toggle="modal"
                                data-target="#configModal"
                                disabled={this.props.layoutBuilder.isPending}
                            >
                                <i className="fa fa-cog" />
                                {' '}
                                Config
                            </button>
                            <button
                                className="btn btn-success btn-lg"
                                type="button"
                                disabled={this.props.layoutBuilder.isPending || this.state.syncDisabled}
                                onClick={async () => {
                                    this.setState({ syncDisabled: true });
                                    await this.props.syncProductData();
                                    this.setState({ syncDisabled: false });
                                    swal({
                                        title: 'Syncing Product',
                                        text: 'Syncing Product Success.',
                                        type: 'success',
                                        confirmButtonText: 'OK',
                                        closeOnConfirm: false,
                                    }, () => {
                                        window.location.reload();
                                    });
                                }}
                            >
                                {this.state.syncDisabled
                                    ? (
                                        <span>
                                            <i className="fa fa-spinner fa-spin fa-fw" />
                                            {' '}
                                            Syncing...
                                        </span>
                                    )
                                    : (
                                        <span>
                                            <i className="fa fa-refresh" />
                                            {' '}
                                            Sync Product
                                        </span>
                                    )}
                            </button>
                            {!isPubllished
                                ? (
                                    <button
                                        className="btn btn-primary btn-lg"
                                        type="button"
                                        onClick={this.props.saveBuilding}
                                        disabled={this.props.layoutBuilder.isPending}
                                    >
                                        {this.props.layoutBuilder.isPending
                                            ? (
                                                <span>
                                                    <i className="fa fa-spinner fa-spin fa-fw" />
                                                    {' '}
                                                    Saving...
                                                </span>
                                            )
                                            : (
                                                <span>
                                                    <i className="fa fa-floppy-o" />
                                                    {' '}
                                                    Save
                                                </span>
                                            )}
                                    </button>
                                ) : ''}
                        </div>
                    </div>
                    <div className="clearfix" />
                    {notification}
                    {$imagePreview}
                    <ConfigModal
                        apply={this._apply}
                        clear={this._clear}
                        imageRatio={this.props.layoutBuilder.layoutImageRatio}
                        project={this.props.layoutBuilder.project}
                        handleImageChange={this._handleImageChange}
                        onRatioChange={this._onRatioChange}
                        changeBuildingName={this.props.changeBuildingName}
                        buildingName={this.props.layoutBuilder.layoutBuildingName}
                        changePublishStatus={this.props.changePublishStatus}
                        publishStatus={this.props.layoutBuilder.published}
                        layoutBuilderData={this.props.layoutBuilder.layoutBuilderData}
                    />
                    <AttributeModal
                        layoutBuilderData={this.props.layoutBuilder.layoutBuilderData}
                        modalRef={(el) => { this.attributeModal = el; }}
                        changeAttribute={this.props.changeAttribute}
                        toggleSelectAll={this.props.toggleSelectAll}
                        undo={this.props.undo}
                        unlinkProduct={this.props.unlinkProduct}
                        toggleAlign={this.props.toggleAlign}
                        deleteCell={this.props.deleteCell}
                        imageHeight={this.state.imageHeight * (this.state.imageRatio / 100)}
                        addCell={this.props.addCell}
                    />
                    <FindProjectModal
                        findProject={this.props.findProject}
                        allProject={this.state.filteredProjects}
                        pickProject={this._pickProject}
                    />
                    <FindProductModal
                        products={this.props.layoutBuilder.productList}
                        pickProduct={this.props.pickProduct}
                        layoutBuilderData={this.props.layoutBuilder.layoutBuilderData}
                    />
                    <ProductInfoModal
                        modalRef={(el) => { this.productInfoModal = el; }}
                        layoutBuilderData={this.props.layoutBuilder.layoutBuilderData}
                        toggleSelectAll={this.props.toggleSelectAll}
                        imageHeight={this.state.imageHeight}
                        instanceUrl={this.props.token.instanceUrl}
                        productPicked={this.props.layoutBuilder.layoutProductPicked}
                        profile={this.props.profile}
                    />
                </div>
            </div>
        );
    }
}

LayoutBuilder.propTypes = {
    layoutBuilder: PropTypes.object.isRequired,
    changeImage: PropTypes.func.isRequired,
    buildNewCell: PropTypes.func.isRequired,
    deleteCell: PropTypes.func.isRequired,
    toggleCell: PropTypes.func.isRequired,
    clearCell: PropTypes.func.isRequired,
    changeAttribute: PropTypes.func.isRequired,
    toggleSelectAll: PropTypes.func.isRequired,
    undo: PropTypes.func.isRequired,
    findProject: PropTypes.func.isRequired,
    findAllProduct: PropTypes.func.isRequired,
    pickProduct: PropTypes.func.isRequired,
    pickProject: PropTypes.func.isRequired,
    unlinkProduct: PropTypes.func.isRequired,
    saveBuilding: PropTypes.func.isRequired,
    changeBuildingName: PropTypes.func.isRequired,
    changeImageRatio: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    loadOneLayout: PropTypes.func.isRequired,
    selectManyCell: PropTypes.func.isRequired,
    toggleAlign: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    changePublishStatus: PropTypes.func.isRequired,
    syncProductData: PropTypes.func.isRequired,
    addCell: PropTypes.func.isRequired,
    token: PropTypes.object,
    profile: PropTypes.object.isRequired,
};

LayoutBuilder.defaultProps = {
    isLoading: false,
    token: {},
};

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        changeImage,
        buildNewCell,
        deleteCell,
        toggleCell,
        clearCell,
        changeAttribute,
        toggleSelectAll,
        undo,
        getUserToken,
        findProject,
        findAllProduct,
        pickProject,
        pickProduct,
        unlinkProduct,
        saveBuilding,
        changeBuildingName,
        changeImageRatio,
        loadOneLayout,
        selectManyCell,
        toggleAlign,
        changePublishStatus,
        syncProductData,
        addCell,
    }, dispatch));

function mapStateToProps(state) {
    return {
        layoutBuilder: state.layoutBuilder,
        isLoading: state.common.isLoading,
        token: state.salesforce.token,
        profile: state.salesforce.userInfo.profile,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutBuilder);
