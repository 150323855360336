import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import FireBaseUserReducer from './firebase_user_reducer';
import LayoutBuilderReducer from './layout_builder_reducer';
import SalesforceReducer from './salesforce_reducer';
import CommonReducers from './common_reducer';
import AdminReducers from './admin_reducer';
import QueryReducers from './query_reducer';
import ProfileReducers from './profiles_reducer';

const rootReducer = combineReducers({
    currentUser: FireBaseUserReducer,
    routing: routerReducer,
    layoutBuilder: LayoutBuilderReducer,
    salesforce: SalesforceReducer,
    common: CommonReducers,
    admin: AdminReducers,
    buildingQuery: QueryReducers,
    profile: ProfileReducers,
});

export default rootReducer;
