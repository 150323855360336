import React from 'react';
import { Link, browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { firestore } from '../../utils/firebase';
import { findProject } from '../layoutbuilder/actions';
import Checkbox from '../helpers/checkbox';

class NewProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            createdBy: `${this.props.userInfo.firstname} ${this.props.userInfo.lastname}`,
            onLoading: false,
            productVisible: false,
            holdVisible: false,
            projectVisible: false,
            menuVisible: false,
            holdData: [{
                id: 'Create',
                name: 'Create',
                enable: false,
            }, {
                id: 'ViewOthersHold',
                name: 'View Hold Queue',
                enable: false,
            }, {
                id: 'ViewHoldQueueEmail',
                name: 'View Hold Queue Email',
                enable: false,
            },
            ],
            productField: [],
            projects: [],
            menuData: [{
                id: 'PropertyManagement',
                name: 'Property Management',
                enable: false,
            }, {
                id: 'BuildingQuery',
                name: 'Building Query',
                enable: false,
            }, {
                id: 'UserManagement',
                name: 'User Management',
                enable: false,
            }, {
                id: 'Profiles',
                name: 'Profiles',
                enable: false,
            }],
            unitActionData: [{
                id: 'sendBrochure',
                name: 'Send Brochure',
                enable: false,
            }, {
                id: 'newProspect',
                name: 'New Prospect',
                enable: false,
            }, {
                id: 'hold',
                name: 'Hold',
                enable: false,
            }, {
                id: 'followUpAction',
                name: 'Follow Up Action',
                enable: false,
            }],
            role: 'admin',
            allProductFieldReadAccess: false,
            allProductFieldEditAccess: false,
            allProjectsEnable: false,
            allMenuEnable: false,
            allUnitActionEnable: false,
            viewHoldQueueEmail: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setActiveProject = this.setActiveProject.bind(this);
        this.setActiveHold = this.setActiveHold.bind(this);
        this.setActiveRead = this.setActiveRead.bind(this);
        this.setActiveEdit = this.setActiveEdit.bind(this);
        this.setActiveMenu = this.setActiveMenu.bind(this);
        this.setActiveUnitAction = this.setActiveUnitAction.bind(this);
        this.handleChangeEnable = this.handleChangeEnable.bind(this);
        this.changeAllProductFieldAccess = this.changeAllProductFieldAccess.bind(this);
        this.changeAllEnable = this.changeAllEnable.bind(this);
    }

    componentWillMount() {
        this.props.findProject();
        this.setupProductField();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.allProject !== this.state.projects) {
            this.setupProjects();
        }
    }

    setupProductField() {
        const productField = [];
        this.props.productField.forEach((e) => {
            const a = {
                name: e.name,
                label: e.label,
                type: e.type,
                readAccess: false,
                editAccess: false,
            };
            productField.push(a);
        });
        this.setState({ productField });
    }

    setupProjects() {
        const projects = [];
        this.props.allProject.forEach((e) => {
            const a = {
                id: e.Id,
                name: e.Name,
                enable: false,
            };
            projects.push(a);
        });
        this.setState({ projects });
    }

    dropdown(value) {
        return value ? 'fa fa-caret-down' : 'fa fa-caret-right';
    }

    setActiveMenu(cell, row) {
        return (
            <Checkbox
                name={row.name}
                onChange={(() => {
                    this.setState({ menuData: this.handleChangeEnable(cell, row, this.state.menuData) });
                })}
                checked={cell}
            />
        );
    }

    setActiveProject(cell, row) {
        return (
            <Checkbox
                name={row.name}
                onChange={(() => {
                    this.setState({ projects: this.handleChangeEnable(cell, row, this.state.projects) });
                })}
                checked={cell}
            />
        );
    }

    setActiveHold(cell, row) {
        const disabled = row.id === 'ViewHoldQueueEmail' ? this.state.viewHoldQueueEmail : false;
        return (
            <Checkbox
                name={row.name}
                onChange={(() => {
                    this.setState({ holdData: this.handleChangeEnable(cell, row, this.state.holdData) });
                })}
                disabled={disabled}
                checked={cell}
            />
        );
    }

    setActiveRead(cell, row) {
        return (
            <Checkbox
                name={row.name}
                onChange={(() => {
                    this.handleChangeProduct(cell, row, 'read');
                })}
                checked={cell}
            />
        );
    }

    setActiveEdit(cell, row) {
        return (
            <Checkbox
                name={row.name}
                onChange={(() => {
                    this.handleChangeProduct(cell, row, 'edit');
                })}
                checked={cell}
            />
        );
    }

    setActiveUnitAction(cell, row) {
        return (
            <Checkbox
                name={row.name}
                onChange={(() => {
                    this.setState({ unitActionData: this.handleChangeEnable(cell, row, this.state.unitActionData) });
                })}
                checked={cell}
            />
        );
    }

    handleSubmit(event) {
        const objProductField = {};
        this.state.productField.forEach((e) => {
            objProductField[e.name] = {
                name: e.name,
                label: e.label,
                type: e.type,
                readAccess: e.readAccess,
                editAccess: e.editAccess,
            };
        });

        const objHold = {};
        this.state.holdData.forEach((e) => {
            objHold[e.id] = {
                id: e.id,
                name: e.name,
                enable: e.enable,
            };
        });

        const objProjects = {};
        this.state.projects.forEach((e) => {
            objProjects[e.id] = {
                id: e.id,
                name: e.name,
                enable: e.enable,
            };
        });

        const objMenu = {};
        this.state.menuData.forEach((e) => {
            objMenu[e.id] = {
                id: e.id,
                name: e.name,
                enable: e.enable,
            };
        });

        const objUnitAction = {};
        this.state.unitActionData.forEach((e) => {
            objUnitAction[e.id] = {
                id: e.id,
                name: e.name,
                enable: e.enable,
            };
        });

        this.setState({ onLoading: true });
        firestore.collection('Profiles').add({
            ProfileName: this.state.name,
            Description: this.state.description,
            Active: true,
            CreatedDate: new Date(),
            CreatedBy: this.state.createdBy,
            ProductField: objProductField,
            Hold: objHold,
            Projects: objProjects,
            Menu: objMenu,
            UnitAction: objUnitAction,
            Role: this.state.role,
        }).then((ref) => {
            this.setState({ onLoading: false });
            browserHistory.push('/profiles');
        });

        event.preventDefault();
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleChangeProduct(cell, row, type) {
        const { productField } = this.state;
        const foundIndex = productField.findIndex((e) => e.name === row.name);

        if (type === 'read') {
            if (row.readAccess === true) {
                row.editAccess = false;
            }
            row.readAccess = !row.readAccess;
        } else if (type === 'edit') {
            if (row.editAccess === false) {
                row.readAccess = true;
            }
            row.editAccess = !row.editAccess;
        }

        productField[foundIndex] = row;
        this.setState({ productField });
    }

    handleChangeEnable(cell, row, state) {
        const arrangedList = state;
        const foundIndex = state.findIndex((e) => e.name === row.name);

        if (row.id === 'ViewOthersHold') {
            if (row.enable) {
                this.setState({ viewHoldQueueEmail: true });
                arrangedList[2].enable = false;
            } else {
                this.setState({ viewHoldQueueEmail: false });
            }
        }

        row.enable = !row.enable;

        arrangedList[foundIndex] = row;
        return arrangedList;
    }

    changeAllProductFieldAccess(val, type) {
        const { productField } = this.state;
        if (type === 'read') {
            this.setState({ allProductFieldReadAccess: !val });
            productField.forEach((e) => {
                e.readAccess = !val;
                if (val === true) {
                    e.editAccess = false;
                    this.setState({ allProductFieldEditAccess: false });
                }
            });
        } else {
            this.setState({ allProductFieldEditAccess: !val });
            productField.forEach((e) => {
                e.editAccess = !val;
                if (val === false) {
                    e.readAccess = true;
                    this.setState({ allProductFieldReadAccess: true });
                }
            });
        }
    }

    changeAllEnable(val, state, data) {
        this.setState({ [state]: !val });
        data.forEach((e) => {
            e.enable = !val;
            if (e.id === 'ViewHoldQueueEmail') {
                if (e.enable) {
                    this.setState({ viewHoldQueueEmail: false });
                } else {
                    this.setState({ viewHoldQueueEmail: true });
                }
            }
        });
    }

    render() {
        return (
            <div>
                <section className="content-header">
                    <h1>Add Profile</h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/">
                                <i className="fa fa-dashboard" />
                                {' '}
                                Home
                            </Link>
                        </li>
                        <li><Link to="/profiles">Profiles</Link></li>
                        <li className="active">Add</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box box-primary">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Profile Information</h3>
                                </div>
                                <form id="frmProfile" onSubmit={this.handleSubmit}>
                                    <div className="box-body">
                                        <div className="form-group required">
                                            <label htmlFor="name">Name:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                value={this.state.name}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="description">Description</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="description"
                                                value={this.state.description}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="createdBy">Created By</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="createdBy"
                                                value={this.state.createdBy}
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="accessType">Access Type</label>
                                            <select name="role" ref="role" id="role" className="form-control" onChange={this.handleChange} required>
                                                <option key="admin" value="admin">Admin</option>
                                                <option key="management" value="management ">Management</option>
                                                <option key="dm" value="dm">Mobile – DM</option>
                                                <option key="sales" value="sales">Mobile – Sales</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Profile Item</h3>
                                    </div>
                                    <div className="box-body">
                                        <a href="#" onClick={() => this.setState({ productVisible: !this.state.productVisible })}>
                                            <i className={this.dropdown(this.state.productVisible)} />
&nbsp;Product
                                        </a>
                                        {
                                            this.state.productVisible
                                                ? (
                                                    <BootstrapTable
                                                        bordered
                                                        condensed
                                                        striped
                                                        hover
                                                        data={this.state.productField}
                                                    >
                                                        <TableHeaderColumn isKey dataField="label">Field Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="type">Field Type</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="readAccess" dataFormat={this.setActiveRead}>
                                                            <Checkbox
                                                                name="productFieldReadAll"
                                                                onChange={(() => {
                                                                    this.changeAllProductFieldAccess(this.state.allProductFieldReadAccess, 'read');
                                                                })}
                                                                checked={this.state.allProductFieldReadAccess}
                                                            />
                                                            &nbsp;
                                                            Read Access
                                                        </TableHeaderColumn>
                                                        <TableHeaderColumn dataField="editAccess" dataFormat={this.setActiveEdit}>
                                                            <Checkbox
                                                                name="productFieldEditAll"
                                                                onChange={(() => {
                                                                    this.changeAllProductFieldAccess(this.state.allProductFieldEditAccess, 'edit');
                                                                })}
                                                                checked={this.state.allProductFieldEditAccess}
                                                            />
                                                            &nbsp;
                                                            Edit Access
                                                        </TableHeaderColumn>
                                                    </BootstrapTable>
                                                )
                                                : null
                                        }
                                    </div>
                                    <div className="box-body">
                                        <a href="#" onClick={() => this.setState({ holdVisible: !this.state.holdVisible })}>
                                            <i className={this.dropdown(this.state.holdVisible)} />
&nbsp;Hold
                                        </a>
                                        {
                                            this.state.holdVisible
                                                ? (
                                                    <BootstrapTable
                                                        bordered
                                                        condensed
                                                        striped
                                                        hover
                                                        data={this.state.holdData}
                                                    >
                                                        <TableHeaderColumn isKey dataField="name">Field Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="enable" dataFormat={this.setActiveHold}>
                                                            <Checkbox
                                                                name="holdEnableAll"
                                                                onChange={(() => {
                                                                    this.changeAllEnable(this.state.allHoldEnable, 'allHoldEnable', this.state.holdData);
                                                                })}
                                                                checked={this.state.allHoldEnable}
                                                            />
                                                            &nbsp;
                                                            Enable
                                                        </TableHeaderColumn>
                                                    </BootstrapTable>
                                                )
                                                : null
                                        }
                                    </div>
                                    <div className="box-body">
                                        <a href="#" onClick={() => this.setState({ projectVisible: !this.state.projectVisible })}>
                                            <i className={this.dropdown(this.state.projectVisible)} />
&nbsp;Projects
                                        </a>
                                        {
                                            this.state.projectVisible
                                                ? (
                                                    <BootstrapTable
                                                        bordered
                                                        condensed
                                                        striped
                                                        hover
                                                        data={this.state.projects}
                                                    >
                                                        <TableHeaderColumn isKey dataField="name">Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="enable" dataFormat={this.setActiveProject}>
                                                            <Checkbox
                                                                name="projectsEnableAll"
                                                                onChange={(() => {
                                                                    this.changeAllEnable(this.state.allProjectsEnable, 'allProjectsEnable', this.state.projects);
                                                                })}
                                                                checked={this.state.allProjectsEnable}
                                                            />
                                                            &nbsp;
                                                            Enable
                                                        </TableHeaderColumn>
                                                    </BootstrapTable>
                                                )
                                                : null
                                        }
                                    </div>
                                    <div className="box-body">
                                        <a href="#" onClick={() => this.setState({ menuVisible: !this.state.menuVisible })}>
                                            <i className={this.dropdown(this.state.menuVisible)} />
&nbsp;Menu
                                        </a>
                                        {
                                            this.state.menuVisible
                                                ? (
                                                    <BootstrapTable
                                                        bordered
                                                        condensed
                                                        striped
                                                        hover
                                                        data={this.state.menuData}
                                                    >
                                                        <TableHeaderColumn isKey dataField="name">Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="enable" dataFormat={this.setActiveMenu}>
                                                            <Checkbox
                                                                name="menuEnableAll"
                                                                onChange={(() => {
                                                                    this.changeAllEnable(this.state.allMenuEnable, 'allMenuEnable', this.state.menuData);
                                                                })}
                                                                checked={this.state.allMenuEnable}
                                                            />
                                                            &nbsp;
                                                            Enable
                                                        </TableHeaderColumn>
                                                    </BootstrapTable>
                                                )
                                                : null
                                        }
                                    </div>
                                    <div className="box-body">
                                        <a href="#" onClick={() => this.setState({ unitActionVisible: !this.state.unitActionVisible })}>
                                            <i className={this.dropdown(this.state.unitActionVisible)} />
&nbsp;Unit Action
                                        </a>
                                        {
                                            this.state.unitActionVisible
                                                ? (
                                                    <BootstrapTable
                                                        bordered
                                                        condensed
                                                        striped
                                                        hover
                                                        data={this.state.unitActionData}
                                                    >
                                                        <TableHeaderColumn isKey dataField="name">Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="enable" dataFormat={this.setActiveUnitAction}>
                                                            <Checkbox
                                                                name="unitActionEnableAll"
                                                                onChange={(() => {
                                                                    this.changeAllEnable(this.state.allUnitActionEnable, 'allUnitActionEnable', this.state.unitActionData);
                                                                })}
                                                                checked={this.state.allUnitActionEnable}
                                                            />
                                                            &nbsp;
                                                            Enable
                                                        </TableHeaderColumn>
                                                    </BootstrapTable>
                                                )
                                                : null
                                        }
                                    </div>
                                    <div className="box-footer">
                                        <Link
                                            to="/profiles"
                                            className="btn btn-default"
                                            type="button"
                                        >
                                            Cancel
                                        </Link>
                                        <button type="submit" className="btn btn-info pull-right" disabled={this.state.onLoading}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.salesforce.userInfo,
        productField: state.salesforce.productField.fields,
        allProject: state.layoutBuilder.allProject,
    };
}

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        findProject,
    }, dispatch)
);

NewProfile.propTypes = {
    userInfo: PropTypes.object.isRequired,
    productField: PropTypes.object.isRequired,
    allProject: PropTypes.object.isRequired,
    findProject: PropTypes.func.isRequired,
};

NewProfile.defaultProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(NewProfile);
