import React from 'react';
import { Link, browserHistory } from 'react-router';
import propTypes from 'prop-types';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this._logout = this._logout.bind(this);
    }

    _logout() {
        this.props.logoutUser();
        browserHistory.push('/login');
    }

    render() {
        const { props } = this;

        if (props.currentUser === null) {
            return <div />;
        }

        return (<header className="main-header">
            <Link to={'/'} className="logo">
                <span className="logo-mini"><b>R</b>L</span>
                <span className="logo-lg"><b>Raimon</b> Land</span>
            </Link>
            <nav className="navbar navbar-static-top">
                <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
                    <span className="sr-only">Toggle navigation</span>
                </a>
                <div className="navbar-custom-menu">
                    <ul className="nav navbar-nav">
                        <li className="dropdown user user-menu">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                <img
                                    src={props.currentUser.photoURL || `/${require('../../img/default-avatar.png')}`}
                                    className="user-image"
                                    alt="Avatar"
                                />
                                <span className="hidden-xs"> {props.currentUser.displayName}</span>
                            </a>
                            <ul className="dropdown-menu">
                                <li className="user-header">
                                    <img
                                        src={props.currentUser.photoURL || `/${require('../../img/default-avatar.png')}`}
                                        className="img-circle"
                                        alt="Avatar"
                                    />
                                    <p>
                                        {props.currentUser.displayName}
                                        <small>{props.currentUser.email}</small>
                                    </p>
                                </li>
                                <li className="user-footer">
                                    <div className="pull-left">
                                        <Link to={'profile'} className="btn btn-default btn-flat">Settings</Link>
                                    </div>
                                    <div className="pull-right">
                                        <a onClick={this._logout} className="btn btn-default btn-flat">Sign out</a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>);
    }
}


const renderUserMenu = (currentUser) => {
    // if current user exists and user id exists than make user navigation
    if (currentUser && currentUser.uid) {
        return (
            <li className="dropdown">
                <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {currentUser.email} <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                    <li><Link to="/profile">Profile</Link></li>
                    <li role="separator" className="divider" />
                    <li><Link to="/logout" onClick={this.logOut}>Logout</Link></li>
                </ul>
            </li>
        );
    } 

    return [
        <li key={1}><Link to="/login">Login</Link></li>,
        <li key={2}><Link to="/register">Register</Link></li>,
    ];
};

Header.propTypes = {
    logoutUser: propTypes.func.isRequired,
};
