import React from 'react';
import PropTypes from 'prop-types';

const ConfigModal = (props) => {
    let rows = '';
    let cols = '';
    let forNewBuild = false;
    return (
        <div className="modal modal-info" id="configModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title" id="myModalLabel">Building Configuration</h4>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="form-group col-sm-12 col-lg-12">
                        <label htmlFor="rows">Project Name: </label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search for project..." value={props.project ? props.project.Name : ''} />
                            <span className="input-group-btn">
                                <button className="btn btn-default" type="button" data-toggle="modal" data-target="#findProjectModal">
                                    <i className="fa fa-search" />
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="form-group col-sm-6">
                        <label htmlFor="rows">Building Name: </label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="" value={props.buildingName} onChange={(e) => props.changeBuildingName(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group col-sm-6">
                        <label htmlFor="rows">Image Ratio: </label>
                        <div className="input-group">
                            <input type="number" className="form-control" placeholder="" value={props.imageRatio} onChange={props.onRatioChange} />
                        </div>
                    </div>
                    <div className="clearfix" />
                    {/* }
                <div className="form-group col-sm-12 col-lg-12">
                    <label htmlFor="publishedStatus">Published Status: </label>
                    <div className="input-group">
                        <select name="publishedStatus" className="form-control" onChange={e => props.changePublishStatus(e.target.value)}>
                            <option value="Unpublished">Unpublished</option>
                            <option value="Web">Web</option>
                            <option value="Mobile">Mobile</option>
                            <option value="Both">Both</option>
                        </select>
                    </div>
                </div>
                <div className="clearfix" /> */}
                    <div className="form-group">
                        <label htmlFor="shiloutte">Please Choose Shiloutte Background</label>
                        <form>
                            <input type="file" onChange={props.handleImageChange} name="shiloutte" />
                        </form>
                    </div>
                    <div className="">
                        <button className="btn btn-default btn-sm" onClick={() => forNewBuild.style.display = forNewBuild.style.display === 'block' ? 'none' : 'block'}> Show generate row and col </button>
                    </div>
                    <div className="forNewBuild" ref={(ref) => (forNewBuild = ref)} style={{ display: props.layoutBuilderData.length > 0 ? 'none' : 'block' }}>
                        <div className="form-group col-sm-6">
                            <label htmlFor="rows">Rows: </label>
                            <input name="rows" type="text" ref={(el) => { rows = el; }} className="form-control" />
                        </div>
                        <div className="form-group col-sm-6">
                            <label htmlFor="cols">Cols: </label>
                            <input name="cols" type="text" ref={(el) => { cols = el; }} className="form-control" />
                        </div>
                        <div className="clearfix" />
                        <div className="form-group col-sm-6">
                            <button type="button" className="btn btn-success btn-sm" value="Apply" onClick={() => props.apply(rows.value, cols.value)}>Apply</button>
                            <button type="button" className="btn btn-default btn-sm" value="Reset" onClick={props.clear}>Reset</button>
                        </div>
                        <div className="clearfix" />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-success btn-lg" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">Save</span></button>
                </div>
            </div>
        </div>
    );
};

ConfigModal.propTypes = {
    handleImageChange: PropTypes.func.isRequired,
    imageRatio: PropTypes.number.isRequired,
    project: PropTypes.object.isRequired,
    apply: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
    onRatioChange: PropTypes.func.isRequired,
    changeBuildingName: PropTypes.func.isRequired,
    buildingName: PropTypes.string,
    changePublishStatus: PropTypes.func.isRequired,
    layoutBuilderData: PropTypes.any,
};

ConfigModal.defaultProps = {
    buildingName: '',
    layoutBuilderData: [],
};

export default ConfigModal;
