import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';

const ProductInfoModal = (props) => {
    let price;
    if (props.product.Sales_Status__c === 'Sold' || props.product.Sales_Status__c === 'Booked') {
        price = props.profile.ProductField.Net_Unit_Furniture_Price__c.readAccess ? props.product.Net_Unit_Furniture_Price__c : '';
    } else {
        price = props.profile.ProductField.Price__c.readAccess ? props.product.Price__c : '';
    }

    return (
        <div
            className="modalAttribute productInfo"
            id="modalInfo"
            style={{ position: 'absolute', left: props.left, top: props.top, width: 400, height: 250, display: props.isVisible ? 'block' : 'none' }}
            ref={props.reference}
        >
            <div className="close">
                <button className="close-btn btn" onClick={() => props.changeFormValue({ attribute: 'productInfoShown', value: false })} >
                    <i className="fa fa-times" />
                </button>
            </div>
            <div className="header" style={{ backgroundColor: '#aaa' }}>Product Information</div>
            <div className="content">
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td>Unit Name</td>
                            <td>:</td>
                            <td><a target="_blank" href={`${props.instanceUrl}/${props.product.Id}`}>{props.product.Name}</a></td>
                        </tr>
                        { props.profile.ProductField.Net_Unit_Furniture_Price__c.readAccess && props.profile.ProductField.Price__c.readAccess ?
                            <tr>
                                <td>{props.product.Sales_Status__c === 'Sold' ? 'Sold Price' : 'Title Deed Price' }</td>
                                <td>:</td>
                                <td>{new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' })
                                        .format(price)}</td>
                            </tr>
                        : null }
                        { props.profile.ProductField.Sales_Status__c.readAccess ?
                            <tr>
                                <td>Sales Status</td>
                                <td>:</td>
                                <td>{props.product.Sales_Status__c}</td>
                            </tr>
                        : null }
                        { props.profile.ProductField.Unit_Status__c.readAccess ?
                            <tr>
                                <td>Unit Status</td>
                                <td>:</td>
                                <td>{props.product.Unit_Status__c}</td>
                            </tr>
                        : null }
                        { props.profile.ProductField.Quota__c.readAccess ?
                            <tr>
                                <td>Quota</td>
                                <td>:</td>
                                <td>{props.product.Quota__c || '-'}</td>
                            </tr>
                        : null }
                        { props.profile.ProductField.Close_Date__c.readAccess ?
                            <tr>
                                <td>Close Date</td>
                                <td>:</td>
                                <td>{props.product.Close_Date__c ? moment(props.product.Close_Date__c).format('DD-MMM-YYYY') : '-'}</td>
                            </tr>
                        : null }
                    </tbody>
                </table>
            </div>
        </div>);
};

ProductInfoModal.propTypes = {
    product: propTypes.object,
    changeFormValue: propTypes.func.isRequired,
    reference: propTypes.func.isRequired,
    isVisible: propTypes.bool,
    left: propTypes.number,
    top: propTypes.number,
    instanceUrl: propTypes.string,
    profile: propTypes.object.isRequired,
};


ProductInfoModal.defaultProps = {
    isVisible: false,
    left: 80,
    top: 200,
    product: {},
    instanceUrl: 'https://login.salesforce.com/',
};

export default ProductInfoModal;

