import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import 'sweetalert/dist/sweetalert.css';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { createUser, saveProfile } from './actions';
import { loadAllProfile } from '../profiles/actions';
import { firestore } from '../../utils/firebase';

class UserNew extends Component {
    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.state = {
            message: '',
            profileList: [],
            role: 'admin',
        };
        this.handleChangeProfile = this.handleChangeProfile.bind(this);
    }

    componentWillMount() {
        this.loadProfiles();
    }

    onFormSubmit(event) {
        event.preventDefault();

        const email = this.refs.email.value;
        const password = this.refs.password.value;
        const firstname = this.refs.firstname.value;
        const lastname = this.refs.lastname.value;
        const active = this.refs.active.checked;
        const { role } = this.state;
        const profile = this.refs.profile.value;

        this.props.createUser({
            email, password, firstname, lastname, active, role,
        }).then((data) => {
            console.log('new_user', data);
            if (!data.success) {
                swal({
                    title: 'Cannot Create User!',
                    text: data.message,
                    type: 'error',
                });
            } else {
                const { uid } = data.newuser;
                this.props.saveProfile({
                    uid, email, firstname, lastname, active, role, profile,
                }).then((data) => {
                    browserHistory.push('/user');
                });
            }
        });
    }

    handleChangeProfile(event) {
        const found = this.state.profileList.find((e) => e.id === event.target.value);
        this.setState({ role: found.data.Role });
    }

    async loadProfiles() {
        const profileList = [{
            id: '',
            data: '',
        }];
        await firestore.collection('Profiles').get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    const { id } = doc;
                    profileList.push({ id, data });
                });
                this.setState({ profileList });
            })
            .catch((err) => {
                console.log('Error getting documents', err);
            });
    }

    render() {
        return (
            <div>
                <section className="content-header">
                    <h1>Add User</h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/">
                                <i className="fa fa-dashboard" />
                                {' '}
                                Home
                            </Link>
                        </li>
                        <li><Link to="/user"> User</Link></li>
                        <li className="active"> Add</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box box-primary">
                                <div className="box-header with-border">
                                    <h3 className="box-title">User Information</h3>
                                </div>
                                <form id="frmRegister" role="form" onSubmit={this.onFormSubmit}>
                                    <div className="box-body">
                                        <div className="form-group required">
                                            <label htmlFor="txtRegEmail">Email address</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                ref="email"
                                                id="txtEmail"
                                                placeholder="Enter email"
                                                name="email"
                                                required
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="txtRegPass">Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                ref="password"
                                                id="txtPass"
                                                placeholder="Password"
                                                name="password"
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="txtRegPass">First Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                ref="firstname"
                                                id="txtFName"
                                                placeholder="First Name"
                                                name="firstname"
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="txtRegPass">Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                ref="lastname"
                                                id="txtLName"
                                                placeholder="Last Name"
                                                name="lastname"
                                                required
                                            />
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="txtRegPass">Profile</label>
                                            <select name="profile" ref="profile" id="profile" className="form-control" onChange={(e) => this.setState({ role: e.target.value })} required>
                                                {this.state.profileList.map((e, key) => <option key={`role${key + 1}key`} value={e.id}>{e.data.ProfileName}</option>)}
                                            </select>
                                        </div>
                                        <div className="checkbox">
                                            <label htmlFor="active">
                                                <input name="active" id="active" ref="active" value="true" type="checkbox" />
                                                {' '}
                                                Active
                                            </label>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <Link
                                            to="/user"
                                            className="btn btn-default"
                                            type="button"
                                        >
                                            Cancel
                                        </Link>
                                        <button type="submit" className="btn btn-info pull-right" disabled={this.props.common.isLoading}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createUser,
        saveProfile,
        loadAllProfile,
    }, dispatch);
}

function mapStateToProps(state) {
    return { admin: state.admin, common: state.common };
}

UserNew.propTypes = {
    admin: PropTypes.object.isRequired,
    loadAllProfile: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNew);
