import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const FilterItem = (props) => {

    return (<div className="form">
        <h4>Filter {props.order + 1} <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => {
                const formValue = props.filterForm;
                formValue.splice(props.order, 1);
                props.changeFormValue({ attribute: 'filterForm', value: formValue });
            }}
        >
            <i className="fa fa-minus" />
        </button></h4>
        <hr />
        {_.times(3, (index) => {
            const attribute = {
                className: 'form-control',
                type: 'text',
                placeholder: 'Value...',
                onChange: e => props.changeFormValue({ attribute: `filterForm.${props.order}.${index}.value`, value: e.target.value }),
                value: props.filterForm[props.order][index] ? props.filterForm[props.order][index].value : '',
            };
            let valueField = (<input
              {...attribute}
            />);

            if (props.filterForm[props.order][index]) {
                let fieldsData = {};
                for (let i = 0; i < props.productField.length; i++) {
                    if (props.filterForm[props.order][index].field === props.productField[i].name) fieldsData = props.productField[i];
                }
                if (fieldsData.picklistValues) {
                    if (fieldsData.picklistValues.length > 0) {
                        valueField = (
                            <select
                                {...attribute}
                            >
                                <option value="" />
                                {fieldsData.picklistValues.map(obj => (
                                    <option key={obj.value + Math.random()} value={obj.value}>{obj.label}</option>
                                ))}
                            </select>
                        );
                    }
                }
                if (props.filterForm[props.order][index].dataType === 'double' || props.filterForm[props.order][index].dataType === 'currency') {
                    valueField = (<input
                        {...attribute}
                        type="number"
                    />);
                } else if (props.filterForm[props.order][index].dataType === 'boolean') {
                    valueField = (
                        <select
                            {...attribute}
                        >
                            <option />
                            <option value>Yes</option>
                            <option value={false}>No</option>
                        </select>);
                } else if (props.filterForm[props.order][index].dataType === 'datetime' || 
                    props.filterForm[props.order][index].dataType === 'date') {
                    valueField = (
                        <DatePicker
                            {...attribute}
                            onChange={date => props.changeFormValue({ attribute: `filterForm.${props.order}.${index}.value`, value: date.toDate() })}
                            selected={props.filterForm[props.order][index] ? moment(props.filterForm[props.order][index].value ? props.filterForm[props.order][index].value : new Date()) : new Date()}
                        />
                    )
                }
            }
            return (<div>
                <div className="form-group col-md-4">
                    <select
                        name={`fieldFilter-${props.order}`}
                        className="form-control"
                        onChange={(e) => {
                            props.changeFormValue({ attribute: `filterForm.${props.order}.${index}.field`, value: e.target.value });
                            props.changeFormValue({ attribute: `filterForm.${props.order}.${index}.value`, value: null });
                            props.changeFormValue({ attribute: `filterForm.${props.order}.${index}.dataType`, value: e.target.selectedOptions[0].title });
                        }}
                        value={props.filterForm[props.order][index] ? props.filterForm[props.order][index].field : ''}
                    >
                        <option value="" />
                        {props.productField.map(object => (
                            <option key={object.name} title={object.type} value={object.name}>{object.label}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <select
                        name={`operator-${props.order}`}
                        className="form-control"
                        onChange={e => props.changeFormValue({ attribute: `filterForm.${props.order}.${index}.operator`, value: e.target.value })}
                        value={props.filterForm[props.order][index] ? props.filterForm[props.order][index].operator : ''}
                    >
                        <option value="" />
                        <option value="=">Equal</option>
                        <option value="<>">Not Equal</option>
                        <option value=">">Larger Than</option>
                        <option value="<">Lower Than</option>
                        <option value=">=">Larger Than or Equal to</option>
                        <option value="<=">Lower Than or Equal to</option>
                    </select>
                </div>
                <div className="form-group col-md-4">
                    {valueField}
                </div>
                <div className="clearfix" />
            </div>);
        })}
    </div>);
};

FilterItem.propTypes = {
    order: propTypes.number.isRequired,
    productField: propTypes.array.isRequired,
    changeFormValue: propTypes.func.isRequired,
    filterForm: propTypes.object,
};

FilterItem.defaultProps = {
    filterForm: {},
};


export default FilterItem;
