import FireBaseTools, { firebaseDb, firestore } from '../utils/firebase';

import {
    LOGIN_WITH_PROVIDER_FIREBASE,
    REGISTER_FIREBASE_USER,
    LOGIN_FIREBASE_USER,
    FETCH_FIREBASE_USER,
    UPDATE_FIREBASE_USER,
    CHANGE_FIREBASE_USER_PASSWORD,
    FIREBASE_PASSWORD_RESET_EMAIL,
    LOGOUT_FIREBASE_USER,
} from './types';
import { sfRequest } from '../utils/common';

let CONFIG = null;
if (__DEV__) CONFIG = require('../config.dev');
else CONFIG = require('../config');

export function loginWithProvider(provider) {
    const request = FireBaseTools.loginWithProvider(provider);
    return {
        type: LOGIN_WITH_PROVIDER_FIREBASE,
        payload: request,
    };
}

export function registerUser(user) {
    const request = FireBaseTools.registerUser(user);
    return {
        type: REGISTER_FIREBASE_USER,
        payload: request,
    };
}

export async function loginUser(user) {
    let loginInfo;
    const request = await FireBaseTools.loginUser(user);
    if (request.success) {
        const ref = firebaseDb.ref(`/users/${request.uid}/`);

        const snapshot = await ref.once('value');
        let data = snapshot.val();
        if (!data) {
            data = await firestore.collection('users').doc(request.uid).get();
            data = data.data();
        }

        if (data.role === 'admin' || data.role === 'management') {
            loginInfo = request;
        } else {
            FireBaseTools.logoutUser();
            loginInfo = {
                errorCode: 400,
                errorMessage: 'You don\'t have permission to access this resource',
            };
        }
    } else {
        loginInfo = {
            errorCode: request.errorCode,
            errorMessage: request.errorMessage,
        };
    }
    return {
        type: LOGIN_FIREBASE_USER,
        payload: loginInfo,
    };
}

export function storeLoginTimestamp() {
    return {
        type: 'LOGIN_TIMESTAMP',
        payload: new Date().getTime(),
    };
}

export async function getUserInfo(uid) {
    let user = await firestore.collection('users').doc(uid).get();
    user = user.data();

    if (!user) {
        const snapshot = await firebaseDb.ref(`/users/${uid}/`).once('value');
        user = snapshot.val();
    }
    let profile = {};
    await firestore.collection('Profiles').doc(user.profile).get()
        .then((doc) => {
            profile = doc.data();
        })
        .catch((err) => {
            console.log('Error getting documents', err);
        });

    user.profile = profile;

    return {
        type: 'USER_INFO',
        payload: user,
    };
}

export function fetchUser() {
    const request = FireBaseTools.fetchUser();
    return {
        type: FETCH_FIREBASE_USER,
        payload: request,
    };
}

export function updateUser(user) {
    const request = FireBaseTools.updateUserProfile(user);
    return {
        type: UPDATE_FIREBASE_USER,
        payload: request,
    };
}

export function changePassword(newPassword) {
    const request = FireBaseTools.changePassword(newPassword);
    return {
        type: CHANGE_FIREBASE_USER_PASSWORD,
        payload: request,
    };
}

export function resetPasswordEmail(email) {
    const request = FireBaseTools.resetPasswordEmail(email);
    return {
        type: FIREBASE_PASSWORD_RESET_EMAIL,
        payload: request,
    };
}

export function logoutUser(user) {
    const request = FireBaseTools.logoutUser(user);
    return {
        type: LOGOUT_FIREBASE_USER,
        payload: request,
    };
}

export function getUserToken() {
    const request = FireBaseTools.getToken();
    return {
        type: 'GET_TOKEN',
        payload: request,
    };
}

export const getAllProductField = () => (
    (dispatch, getState) => {
        if (getState().currentUser === null) return;
        const salesforceToken = getState().salesforce.token;
        const { productField } = getState().salesforce;
        if (new Date().getTime() - productField.retrievedAt > 60 * 60 * 1000) {
            sfRequest('Product2', {
                method: 'GET',
                url: salesforceToken.instanceUrl,
                id: 'describe',
                accessToken: salesforceToken.accessToken,
            }, ({ type, payload }) => {
                if (type === 'PRODUCT_FIELD') {
                    const { fields } = payload;
                    fields.sort((a, b) => {
                        if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
                        if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
                        return 0;
                    });
                    dispatch({ type, payload: fields });
                } else {
                    dispatch({ type, payload });
                }
            }, 'PRODUCT_FIELD');
        }
    }
);

export function getSalesforceToken(callback) {
    return async (dispatch, getState) => {
        if (getState().currentUser === null) return;
        const token = await getState().currentUser.getIdToken();
        const url = `${CONFIG.FIREBASE_CONFIG.cloudFunctionUrl}salesforce/salesforce-token`;
        const salesforceToken = getState().salesforce.token;
        if (salesforceToken !== null) {
            const timeDifferences = (new Date().getTime() - salesforceToken.issued_at);
            if (timeDifferences < 60 * 60 * 1000) {
                return;
            }
        }
        const json = await fetch(url, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
                mode: 'cors',
            },
            cache: 'default',
        }).then((response) => response.json());
        dispatch({
            type: 'SALESFORCE_TOKEN',
            payload: json,
        });
        if (typeof callback === 'function') callback();
    };
}
