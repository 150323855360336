import { findSelectedCell } from '../utils/common';

const DEFAULT_STATE = {
    layoutBuilderData: [],
    layoutImage: '',
    layoutImageRatio: 100,
    layoutBuilderDataHistory: [],
    project: {},
    layoutBuildingName: '',
    layoutPublished: 'Unpublished',
    layoutProductPicked: {},
    layoutImageUrl: '',
    allProject: [],
    productList: [],
    isPending: false,
    firebaseKey: '',
    allLayout: [],
};
const pushDataToHistory = (state) => {
    const history = state.layoutBuilderDataHistory;
    const layoutBuilderData = JSON.parse(JSON.stringify(state.layoutBuilderData));
    if (history.length > 15) {
        history.pop();
    }
    history.push(layoutBuilderData);
    return history;
};

const fnList = {
    CREATE_NEW_LAYOUT: (state, payload) => ({
        ...state,
        layoutBuilderData: payload,
        layoutBuilderDataHistory: pushDataToHistory(state),
    }),
    CHANGE_LAYOUT_BUILDER_IMAGE: (state, payload) => ({
        ...state,
        layoutImage: payload,
    }),
    DELETE_CELL: (state) => {
        const history = pushDataToHistory(state);
        const { layoutBuilderData } = state;
        for (let i = 0; i < layoutBuilderData.length; i++) {
            if (layoutBuilderData[i]) {
                Object.keys(layoutBuilderData[i]).forEach((j) => {
                    if (layoutBuilderData[i][j] !== undefined && layoutBuilderData[i][j] !== null) {
                        if (layoutBuilderData[i][j].selected) {
                            delete layoutBuilderData[i][j];
                        }
                    }
                });
            }
        }
        return {
            ...state,
            layoutBuilderData,
            layoutBuilderDataHistory: history,
        };
    },
    TOGGLE_CELL: (state, { row, col, ctrlClicked = false }) => {
        const { layoutBuilderData } = state;
        for (let i = 0; i < layoutBuilderData.length; i++) {
            if (layoutBuilderData[i]) {
                Object.keys(layoutBuilderData[i]).forEach((j) => {
                    if (layoutBuilderData[i][j] != undefined) {
                        layoutBuilderData[i][j].selected = !ctrlClicked ? false : layoutBuilderData[i][j].selected;
                    }
                });
            }
        }
        if (layoutBuilderData[row][col]) {
            layoutBuilderData[row][col].selected = !layoutBuilderData[row][col].selected;
        }
        return {
            ...state,
            layoutBuilderData,
        };
    },
    CLEAR_CELL: (state) => ({
        ...state,
        layoutBuilderData: [],
        layoutImage: '',
        layoutBuilderDataHistory: [],
    }),
    CHANGE_ATTRIBUTE: (state, { attributeName, value }) => {
        // const layoutBuilderDataHistory = pushDataToHistory(state);
        const { layoutBuilderData } = state;
        for (let i = 0; i < layoutBuilderData.length; i++) {
            if (layoutBuilderData[i]) {
                Object.keys(layoutBuilderData[i]).forEach((j) => {
                    if (layoutBuilderData[i][j] !== undefined && layoutBuilderData[i][j] !== null) {
                        if (layoutBuilderData[i][j].selected) {
                            layoutBuilderData[i][j][attributeName] = value;
                        }
                    }
                });
            }
        }
        return {
            ...state,
            layoutBuilderData,
        };
    },
    CHANGE_PUBLISH_STATUS: (state, payload) => ({
        ...state,
        layoutPublished: payload,
        published: payload,
    }),
    TOGGLE_SELECT_ALL: (state, status) => {
        const { layoutBuilderData } = state;
        for (let i = 0; i < layoutBuilderData.length; i++) {
            if (layoutBuilderData[i]) {
                Object.keys(layoutBuilderData[i]).forEach((j) => {
                    if (layoutBuilderData[i][j] !== undefined && layoutBuilderData[i][j] !== null) {
                        layoutBuilderData[i][j].selected = status;
                    }
                });
            }
        }
        return {
            ...state,
            layoutBuilderData,
        };
    },
    UNDO: (state) => {
        let { layoutBuilderData } = state;
        if (state.layoutBuilderDataHistory.length > 0) {
            layoutBuilderData = state.layoutBuilderDataHistory[state.layoutBuilderDataHistory.length - 1];
            state.layoutBuilderDataHistory.pop();
        }
        return {
            ...state,
            layoutBuilderData,
        };
    },
    ALL_PROJECT: (state, payload) => ({
        ...state,
        allProject: payload.records,
    }),
    PRODUCT_BY_PROJECT: (state, payload) => {
        if (payload.records.length > 0) {
            return {
                ...state,
                productList: payload.records,
            };
        }

        return state;
    },
    PICK_PRODUCT: (state, payload) => {
        const history = pushDataToHistory(state);
        const { layoutBuilderData } = state;
        for (let i = 0; i < layoutBuilderData.length; i++) {
            if (layoutBuilderData[i]) {
                Object.keys(layoutBuilderData[i]).forEach((j) => {
                    if (layoutBuilderData[i][j] !== undefined && layoutBuilderData[i][j] !== null) {
                        if (layoutBuilderData[i][j].selected) {
                            layoutBuilderData[i][j].product = payload;
                            layoutBuilderData[i][j].label = payload.Name;
                            layoutBuilderData[i][j].productId = payload.Id;
                        }
                    }
                });
            }
        }
        return {
            ...state,
            layoutBuilderData,
            layoutBuilderDataHistory: history,
            layoutProductPicked: {
                ...state.layoutProductPicked,
                [payload.Id]: payload,
            },
        };
    },
    PICK_PROJECT: (state, payload) => ({
        ...state,
        project: payload,
    }),
    CHANGE_BUILDING_NAME: (state, payload) => ({
        ...state,
        layoutBuildingName: payload,
    }),
    UNLINK_PRODUCT: (state) => {
        const history = pushDataToHistory(state);
        const { layoutBuilderData } = state;
        for (let i = 0; i < layoutBuilderData.length; i++) {
            if (layoutBuilderData[i]) {
                Object.keys(layoutBuilderData[i]).forEach((j) => {
                    if (layoutBuilderData[i][j] !== undefined && layoutBuilderData[i][j] !== null) {
                        if (layoutBuilderData[i][j].selected) {
                            delete state.layoutProductPicked[layoutBuilderData[i][j].product.Id];
                            layoutBuilderData[i][j].product = null;
                            layoutBuilderData[i][j].label = `${layoutBuilderData[i][j].rowNo + 1}-${layoutBuilderData[i][j].colNo + 1}`;
                        }
                    }
                });
            }
        }

        return {
            ...state,
            layoutBuilderData,
            layoutBuilderDataHistory: history,
        };
    },
    CHANGE_IMAGE_RATIO: (state, payload) => ({
        ...state,
        layoutImageRatio: payload,
    }),
    LOGOUT_FIREBASE_USER: () => DEFAULT_STATE,
    SAVED: (state, payload) => ({
        ...state,
        isPending: false,
        firebaseKey: payload,
    }),
    PENDING: (state) => ({
        ...state,
        isPending: true,
    }),
    NEW_LAYOUT: (state) => {
        const newState = { ...state, ...DEFAULT_STATE };
        newState.allProject = state.allProject;
        newState.productList = state.productList;
        newState.firebaseKey = '';
        newState.allLayout = state.allLayout;
        return newState;
    },
    CHANGE_LAYOUT_IMAGE_RATIO: (state, payload) => ({
        ...state,
        layoutImageRatio: payload,
    }),
    ALL_LAYOUT_FROM_FIREBASE: (state, payload) => ({
        ...state,
        allLayout: payload,
    }),
    LOAD_ONE_LAYOUT: (state, payload) => {
        let productPicked = {};
        const { layoutBuilderData } = payload.layoutBuilder;
        if (payload.layoutBuilder.layoutProductPicked) {
            productPicked = payload.layoutBuilder.layoutProductPicked;
        } else {
            for (let i = 0; i < layoutBuilderData.length; i++) {
                if (layoutBuilderData[i]) {
                    Object.keys(layoutBuilderData[i]).forEach((j) => {
                        if (layoutBuilderData[i][j] !== undefined && layoutBuilderData[i][j] !== null) {
                            if (layoutBuilderData[i][j].product) {
                                productPicked = {
                                    ...productPicked,
                                    [layoutBuilderData[i][j].product.Id]: layoutBuilderData[i][j].product,
                                };
                            }
                        }
                    });
                }
            }
        }
        return {
            ...state,
            layoutBuilderData: payload.layoutBuilder.layoutBuilderData,
            project: payload.layoutBuilder.project,
            layoutImageRatio: payload.layoutBuilder.layoutImageRatio,
            layoutImage: payload.layoutImage,
            layoutBuildingName: payload.layoutBuilder.layoutBuildingName,
            layoutPublished: payload.layoutBuilder.published,
            published: payload.layoutBuilder.published,
            layoutImageUrl: payload.layoutImageUrl,
            layoutProductPicked: productPicked,
            firebaseKey: payload.firebaseKey,
            productList: payload.allProduct,
        };
    },
    SELECT_MANY_CELL: (state, payload) => {
        const { row, col } = payload;
        const selectedCell = findSelectedCell(state.layoutBuilderData);
        const { layoutBuilderData } = state;
        if (selectedCell.length === 0) {
            layoutBuilderData[row][col].selected = !layoutBuilderData[row][col].selected;
        } else {
            for (let iRow = (selectedCell[0].rowNo < row ? selectedCell[0].rowNo : row);
                iRow <= (selectedCell[0].rowNo > row ? selectedCell[0].rowNo : row); iRow++) {
                for (let iCol = (selectedCell[0].colNo < col ? selectedCell[0].colNo : col);
                    iCol <= (selectedCell[0].colNo > col ? selectedCell[0].colNo : col); iCol++) {
                    if (layoutBuilderData[iRow]) {
                        if (layoutBuilderData[iRow][iCol] !== null
                            && layoutBuilderData[iRow][iCol] !== undefined) layoutBuilderData[iRow][iCol].selected = true;
                    }
                }
            }
        }
        return {
            ...state,
            layoutBuilderData,
        };
    },
    TOGGLE_ALIGN: (state) => {
        const { layoutBuilderData } = state;
        for (let i = 0; i < layoutBuilderData.length; i++) {
            if (layoutBuilderData[i]) {
                Object.keys(layoutBuilderData[i]).forEach((j) => {
                    if (layoutBuilderData[i][j] !== undefined && layoutBuilderData[i][j] !== null) {
                        if (layoutBuilderData[i][j].selected) {
                            layoutBuilderData[i][j].align = layoutBuilderData[i][j].align ? layoutBuilderData[i][j].align + 1 : 1;
                        }
                    }
                });
            }
        }
        return {
            ...state,
            layoutBuilderData,
        };
    },
    ADD_CELL: (state, payload) => {
        const { layoutBuilderData } = state;
        layoutBuilderData[layoutBuilderData.length] = [];
        layoutBuilderData[layoutBuilderData.length - 1][0] = {
            rowNo: layoutBuilderData.length - 1,
            colNo: 0,
            deleted: false,
            selected: true,
            width: 100,
            height: 100,
            left: 0,
            bottom: payload - 100,
            label: 'New Cell',
        };
        return {
            ...state,
            layoutBuilderData,
        };
    },
    FIREBASE_CHANGE_PUBLISH_STATUS: (state, payload) => {
        const { allLayout } = state;
        const index = allLayout.findIndex((layout) => layout.id === payload.key);

        if (index > -1) {
            allLayout[index] = {
                ...allLayout[index],
                published: payload.value,
                layoutPublished: payload.value,
            };
        }
        return {
            ...state,
            allLayout,
        };
    },
};

export default (state = DEFAULT_STATE, { type, payload }) => {
    const fn = fnList[type];
    if (typeof fn === 'function') {
        return fn(state, payload);
    }
    return state;
};
