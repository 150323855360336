import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ButtonGroup, Modal, Button } from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import 'react-bootstrap-table/css/toastr.css';
import { browserHistory, Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    loadAllQuery, loadAllLayout, createNewQuery, getAllProductField, stopListeningQueryRef, deleteQuery,
} from './actions';
import { firestore } from '../../utils/firebase';

const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
};

class LayoutList extends React.Component {
    constructor(props) {
        super(props);
        this.getSelectedLayoutKeys = this.getSelectedLayoutKeys.bind(this);
        this.state = {
            layoutDialogshow: false,
            newAll: [],
            filteredQuery: [],
        };
        this.buildingTable = {};
        this.actionButton = this.actionButton.bind(this);
    }

    componentWillMount() {
        this.loadAllQuery();
        this.props.loadAllLayout();
        this.props.getAllProductField();
    }

    componentWillUnmount() {
        this.props.stopListeningQueryRef();
    }

    getSelectedLayoutKeys() {
        browserHistory.push(`/query/new/${this.buildingTable.state.selectedRowKeys[0]}`);
        this.setState({ layoutDialogshow: false });
    }

    async loadAllQuery() {
        const queries = await firestore.collection('query').limit(200).get();
        const newDataStructure = [];
        const projectProfile = this.props.profile.Projects;

        queries.forEach((query) => {
            const data = query.data();
            if (projectProfile[data.projectId] && projectProfile[data.projectId].enable) {
                newDataStructure.push({
                    ...data,
                    firebaseKey: query.id,
                });
            }
        });
        this.setState({
            newAll: newDataStructure,
        });
    }

    filterQuery(all) {
        const profile = this.props.profile.Projects;

        const filteredQuery = [];
        all.forEach((e) => {
            if (profile[e.projectId].enable === true) {
                filteredQuery.push(e);
            }
        });
        this.setState({ filteredQuery });
    }

    actionButton(cell, row) {
        const viewButton = [];
        viewButton.push(
            <a
                className="btn btn-success btn-sm text-center"
                type="button"
                key={cell}
                href={`/query/edit/${row.buildingKey}/${cell}`}
            >
                <i className="fa fa-eye" />
            </a>,
        );
        viewButton.push(
            <button
                className="btn btn-danger btn-sm text-center"
                type="button"
                key={`${cell}trash`}
                onClick={() => this.props.deleteQuery(cell)}
            >
                <i className="fa fa-trash" />

            </button>,
        );
        return <ButtonGroup>{viewButton}</ButtonGroup>;
    }

    render() {
        const close = () => this.setState({ layoutDialogshow: false });
        const { newAll } = this.state;
        return (
            <div>
                <section className="content-header">
                    <h1>Building Query</h1>
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/">
                                <i className="fa fa-dashboard" />
                                {' '}
                                Home
                            </Link>
                        </li>
                        <li className="active">Building Query</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-4 col-md-offset-4 text-center" style={{ marginBottom: 30 }}>
                            <Button bsStyle="primary" onClick={() => this.setState({ layoutDialogshow: true })}>
                                <i className="fa fa-plus" />
                                {' '}
                                New Query
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                            <BootstrapTable
                                bordered
                                condensed
                                striped
                                hover
                                data={newAll}
                                pagination
                                options={{
                                    sizePerPage: 25,
                                }}
                            >
                                <TableHeaderColumn dataField="projectName">Project Name</TableHeaderColumn>
                                <TableHeaderColumn dataField="name">Query Name</TableHeaderColumn>
                                <TableHeaderColumn dataField="buildingName">Building Name</TableHeaderColumn>
                                <TableHeaderColumn
                                    isKey
                                    dataField="firebaseKey"
                                    width="100"
                                    dataFormat={this.actionButton}
                                >
                                    Action

                                </TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>
                </section>
                <Modal show={this.state.layoutDialogshow} onHide={close} container={this} aria-labelledby="contained-modal-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title">Select Building</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <BootstrapTable
                            data={this.props.allLayout}
                            striped
                            condense
                            hover
                            pagination
                            selectRow={selectRowProp}
                            ref={(ref) => (this.buildingTable = ref)}
                        >
                            <TableHeaderColumn dataField="firebaseKey" isKey dataAlign="center" hidden />
                            <TableHeaderColumn dataField="project" dataFormat={(cell) => (cell ? cell.Name : '')}>Project Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="layoutBuildingName">Building Name</TableHeaderColumn>
                        </BootstrapTable>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="success" onClick={this.getSelectedLayoutKeys}>Select</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        buildingQuery: state.buildingQuery,
        profile: state.salesforce.userInfo.profile,
        allLayout: state.layoutBuilder.allLayout,
    };
}

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        createNewQuery,
        loadAllQuery,
        loadAllLayout,
        getAllProductField,
        stopListeningQueryRef,
        deleteQuery,
    }, dispatch)
);

LayoutList.propTypes = {
    buildingQuery: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    loadAllQuery: PropTypes.func.isRequired,
    loadAllLayout: PropTypes.func.isRequired,
    children: PropTypes.object,
    getAllProductField: PropTypes.func.isRequired,
    stopListeningQueryRef: PropTypes.func.isRequired,
    deleteQuery: PropTypes.func.isRequired,
};

LayoutList.defaultProps = {
    children: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutList);
