const DEFAULT_STATE = {
    isLoading: false,
    loginTimestamp: null,
};
const fnList = {
    TOGGLE_LOADING: state => ({
        ...state,
        isLoading: !state.isLoading,
    }),
    LOGOUT_FIREBASE_USER: () => (
        DEFAULT_STATE
    ),
    DONE: () => ({ isLoading: false }),
    LOADING: () => ({ isLoading: true }),
    LOGIN_TIMESTAMP: (state, payload) => ({ ...state, loginTimestamp: payload }),
};
export default (state = DEFAULT_STATE, { type, payload }) => {
    const fn = fnList[type];
    if (typeof fn === 'function') {
        return fn(state, payload);
    }
    return state;
};
