import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { clearSelection } from '../../utils/common';

const colorCoding = {
    Available: '#42f46b',
    Booked: '#ccc',
    Sold: '#888',
    Reserved: '#b1f909',
    'Not Available': '#9bbef7',
    Hold: 'Orange',
};
class Cell extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            deleted: false,
            ctrlKey: false,
        };

        this._cellClicked = this._cellClicked.bind(this);
        this._onRightClicked = this._onRightClicked.bind(this);
        this._onKeyPress = this._onKeyPress.bind(this);
    }

    _cellClicked(e) {
        this.setState({
            clicked: !this.state.clicked,
        });
        if (e.shiftKey) {
            this.props.onShiftClick({
                row: this.props.rowNo,
                col: this.props.colNo,
            });
        } else {
            this.props.onToggle({
                row: this.props.rowNo,
                col: this.props.colNo,
                ctrlClicked: e.ctrlKey || e.metaKey,
            });
        }
    }

    _onRightClicked(e) {
        if (this.state.clicked) {
            const top = (this.props.imageHeight - (this.props.bottom + 30));
            $('.attributeInfo').css({ left: this.props.left + this.props.width + 100, top });
            const left = this.props.left - this.props.width;
            $('.productInfo').css({ left: left < 0 ? 0 : left, top });
        }
        e.preventDefault();
    }

    _onKeyPress(e) {
        e.preventDefault();
        if (e.key === 'Backspace' || e.key === 'Delete') {
            this.props.onDelete({
                row: this.props.rowNo,
                col: this.props.colNo,
            });
        }        
    }

    render() {
        let backgroundColor = this.props.bgColor;
        let border = '1px solid #999';
        let fontColor = '#222';
        const productLabel = [];
        if (this.props.selected) {
            backgroundColor = 'rgba(234, 255, 255, 0.4)';
            border = '2px solid #ff0000';
        }
        const align = ['left', 'center', 'right'];
        if (this.props.product !== {} && this.props.product !== null && this.props.product !== undefined) {
            backgroundColor = colorCoding[this.props.product.Sales_Status__c];
            fontColor = this.props.product.Unit_Status__c === 'Inactive' || this.props.product.IsActive === false ? '#ff000c' : '#222';
            productLabel.push(<p key={`lbl${this.props.product.Id}`} className="unitLabel">{this.props.label}</p>);
            if (this.props.width > 90) {
                productLabel.push(<p key={`tdp${this.props.product.Id}`} className="totalDeedPrice">{this.props.product.Price__c ? this.props.product.Price__c.toLocaleString() : ''}</p>);
            }
            if (this.props.height > 60) {
                productLabel.push(<p key={`tda${this.props.product.Id}`} className="totalArea">{this.props.product.Actual_Area__c ? `${this.props.product.Actual_Area__c.toLocaleString()}m2` : ''}</p>);
            }
            if (this.props.height > 60 && this.props.width > 90) {
                productLabel.push(<p key={`ppsqm${this.props.product.Id}`} className="quota">{this.props.product.Price_Per_Square__c ? this.props.product.Price_Per_Square__c.toLocaleString() : 0}</p>);
            }
        }
        return (
            <div
                style={{
                    border,
                    backgroundColor,
                    height: this.props.height,
                    width: this.props.width,
                    bottom: (this.props.bottom),
                    left: (this.props.left),
                    position: 'absolute',
                    color: fontColor,
                    padding: 5,
                }}
                onClick={this._cellClicked}
                onContextMenu={this._onRightClicked}
                onKeyDown={this._onKeyPress}
                onKeyUp={this._onKeyUp}
                tabIndex={-1}
                className="productInCell"
            >
                {this.props.product === null ? <p style={{ textAlign: this.props.align ? align[this.props.align % 3] : 'justify' }}>{this.props.label}</p> : productLabel}
            </div>);
    }
}

Cell.propTypes = {
    label: PropTypes.string,
    bgColor: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired,
    rowNo: PropTypes.number.isRequired,
    colNo: PropTypes.number.isRequired,
    selected: PropTypes.bool,
    onToggle: PropTypes.func.isRequired,
    onShiftClick: PropTypes.func.isRequired,
    product: PropTypes.object,
    align: PropTypes.number,
};

Cell.defaultProps = {
    label: '',
    selected: false,
    product: null,
    align: 0,
};

export default Cell;
