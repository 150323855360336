const DEFAULT_STATE = {
    firebaseKey: undefined,
    layoutBuilderData: [],
    layoutImage: '',
    layoutImageRatio: 100,
    layoutProject: {},
    layoutBuildingName: '',
    layoutPublished: 'Unpublished',
    layoutProductPicked: {},
    queryResult: [],
    filterForm: [{}],
    queryFirebaseKey: undefined,
    filterName: '',
    loading: false,
};

const fnList = {
    NEW_QUERY: (state, payload) => {
        return {
            ...state,
            queryData: payload,
        };
    },
    GET_QUERY: (state, payload) => ({
        ...state,
        queryData: payload,
    }),
    ALL_QUERY_FROM_FIREBASE: (state, payload) => ({
        ...state,
        allQuery: payload,
    }),
    ALL_LAYOUT_FROM_FIREBASE: (state, payload) => ({
        ...state,
        allLayout: payload,
    }),
    QUERY_LAYOUT: (state, payload) => ({
        ...state,
        layoutBuilderData: payload.layoutBuilder.layoutBuilderData,
        layoutProject: payload.layoutBuilder.layoutProject,
        layoutImageRatio: payload.layoutBuilder.layoutImageRatio,
        layoutImage: payload.layoutImage,
        layoutBuildingName: payload.layoutBuilder.layoutBuildingName,
        layoutProductPicked: payload.layoutBuilder.layoutProductPicked || {},
        firebaseKey: payload.firebaseKey,
        queryFirebaseKey: payload.queryFirebaseKey,
        filterForm: payload.node.filterForm ? payload.node.filterForm : [{}],
        filterName: payload.node.name,
    }),
    FILTER_FORM: (state, payload) => ({
        ...state,
        filterForm: payload.filterForm,
        filterName: payload.filterName,
    }),
    DATA_FILTER: (state, payload) => ({
        ...state,
        queryResult: payload,
    }),
    LOGOUT_FIREBASE_USER: () => DEFAULT_STATE,
    QUERY_SAVED: (state, payload) => ({
        ...state,
        filterForm: payload.filterForm,
        queryFirebaseKey: payload.firebaseKey,
        filterName: payload.name,
    }),
    TOGGLE_LOADING_FILTER: (state) => ({
        ...state,
        loading: !state.loading,
    }),
};

export default (state = DEFAULT_STATE, { type, payload }) => {
    const fn = fnList[type];
    if (typeof fn === 'function') {
        return fn(state, payload);
    }
    return state;
};
