const DEFAULT_STATE = {
    firebaseKey: '',
    allUser: [],
    userData: {},
};

const fnList = {
    NEW_USER: (state, payload) => {
        return {
            ...state,
            userData: payload,
        };
    },
    GET_USER: (state, payload) => ({
        ...state,
        userData: payload,
    }),
    ALL_USER_FROM_FIREBASE: (state, payload) => ({
        ...state,
        allUser: payload,
    }),
};

export default (state = DEFAULT_STATE, { type, payload }) => {
    const fn = fnList[type];
    if (typeof fn === 'function') {
        return fn(state, payload);
    }
    return state;
};
